import axios from 'axios';
import base64 from 'react-native-base64';
import Cookies from 'js-cookie';
var jwt = require('jsonwebtoken');


const url = process.env.REACT_APP_PRODUCTION_API_URL;
// const url = process.env.REACT_APP_DEVELOPMENT_API_URL;

let config = {
    headers: {
        "withCredentials": true,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTYxODg3ODYyNiwianRpIjoiZTVkZGFkZWYtNWFhZi00NGNiLWJiZDgtY2RmNjllZTdlZTkyIiwibmJmIjoxNjE4ODc4NjI2LCJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzA5Nzg2NDgtYjJlYi00ZjIyLWE3YWQtZjQ0ZjhlMWRkYjY5IiwiZXhwIjoxNjE4OTIxODI2fQ.YT378r4SgkDRhDY5DXN4zOMbdBv-tTvJnOSvGWQL9VI"
        // "access_token_cookie": Cookies.get("access_token_cookie")
    }
}

// let config = {
//     headers: {
//         "withCredentials": true,
//         "Access-Control-Allow-Origin": "*",
//         "x-mock-response-code": 200
//     }
// }

const isAccessTokenExpired = () => {
    let token = Cookies.get("access_token_cookie");

    // for mock api
    if (token === "1234") {
        return false
    }

    var decodedToken=jwt.decode(token, {complete: true});
    var dateNow = new Date();

    if (decodedToken.payload.exp < (dateNow.getTime() / 1000)) {
        return true;
    }

    return false;
}

// POSTS
export async function login(params, success, failure) {
    config.headers.Authorization = 'Basic ' + base64.encode(params.username + ":" + params.password);
    config.headers["Content-Type"] = "application/json";

    axios.post(url + '/login', {}, config).then((response) => {
        Cookies.set('access_token_cookie', response.data.access_token);
        Cookies.set('refresh_token_cookie', response.data.refresh_token);
        success();
    }).catch((error) => {
        failure(error.response);
    })
}

export async function refreshTokens(success) {
    config.headers.Authorization = "Bearer " + Cookies.get("refresh_token_cookie");
    config.headers["Content-Type"] = "application/json";

    axios.post(url + '/refresh_token',{}, config).then((response) => {
        Cookies.set('access_token_cookie', response.data.access_token);
        success();
    }).catch((error) => {
        // failure(error.response);
        console.log('could not refresh access token')
    })
}

export async function signUp(params, success, failure) {
    config.headers.Authorization = 'Basic ' + base64.encode(params.username + ":" + params.password);
    config.headers["Content-Type"] = "application/json";
    axios.post(url + '/user', params, config).then((response) => {
        success();
    }).catch((error) => {
        failure(error.response);
    })
}

export async function createUlincConfig(params, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");
    config.headers["Content-Type"] = "application/json";

    if (isAccessTokenExpired()) {
         refreshTokens(function() {
            createUlincConfig(params, success, failure)
         })
    } else {
        axios.post(url + '/ulinc_config', params, config).then((response) => {
            success(response);
        }).catch((error) => {
            failure(error.response);
        })
    }
    
}

export async function createJaniumCampaign(params, success, failure) {
    config.headers["Content-Type"] = "application/json";

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            createJaniumCampaign(params, success, failure)
        })
   } else {
        axios.post(url + '/janium_campaign', params, config).then((response) => {
            success(response);
        }).catch((error) => {
            failure(error.response);
        })
    }
}

export async function testLiMessaging(params, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");
    config.headers["Content-Type"] = "application/json";

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            testLiMessaging(params, success, failure)
        })
   } else {
        axios.post(url + '/test_li_message_step', params, config).then((response) => {
            success(response);
        }).catch((error) => {
            failure(error.response);
        })
    }
}

export async function testEmailMessaging(params, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");
    config.headers["Content-Type"] = "application/json";

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            testEmailMessaging(params, success, failure)
        })
   } else {
        axios.post(url + '/test_email_step', params, config).then((response) => {
            success(response);
        }).catch((error) => {
            failure(error.response);
        })
    }
}

export async function addEmailConfig(params, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");
    config.headers["Content-Type"] = "application/json";

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            addEmailConfig(params, success, failure)
        })
   } else {
        axios.post(url + '/email_config', params, config).then((response) => {
            success(response);
        }).catch((error) => {
            failure(error.response);
        })
    }
}

export async function sendSingleSenderVerificationEmail(params, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");
    
    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            sendSingleSenderVerificationEmail(params, success, failure)
        })
   } else {
        axios.post(url + '/send_ses_verification_email?email_config_id=' + params.email_config_id, {}, config).then((response) => {
            success(response);
        }).catch((error) => {
            failure(error.response);
        })
    }
}

export async function sendForwardingTestEmail(params, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");
    
    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            sendForwardingTestEmail(params, success, failure)
        })
   } else {
        axios.post(url + '/send_forwarding_test_email?email_config_id=' + params.email_config_id, params, config).then((response) => {
            success(response);
        }).catch((error) => {
            failure(error.response);
        })
    }
}

export async function cancelSubscription(success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");
    
    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            cancelSubscription(success, failure)
        })
   } else {
        axios.post(url + '/cancel_subscription', {}, config).then((response) => {
            success(response);
        }).catch((error) => {
            failure(error.response);
        })
   }
}

// DELETES
export async function logOut(success, failure) {
    Cookies.remove('access_token_cookie');
    Cookies.remove('refresh_token_cookie');

    success();
}

// GETS
export async function getAccountPageData(success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            getAccountPageData(success, failure)
        })
   } else {
        axios.get(url + '/ulinc_configs', config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure();
        })
    }
}

export async function getAccountHomepageData(params, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            getAccountHomepageData(params, success, failure)
        })
   } else {
        axios.get(url + '/ulinc_config?ulinc_config_id=' + params.ulincConfigId, config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure();
        })
    }
}

export async function getCampaignContacts(params, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            getCampaignContacts(params, success, failure)
        })
   } else {
        axios.get(url + '/janium_campaign?janium_campaign_id=' + params.janiumCampaignId, config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure();
        })
    }
}

export async function getEmailSettingsConfigs(success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            getEmailSettingsConfigs(success, failure)
        })
   } else {
        axios.get(url + '/email_configs', config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure();
        })
    }
}

export async function dteVisit(params, success, failure) {
    config.headers["Content-Type"] = "application/json";

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            dteVisit(params, success, failure)
        })
   } else {
        axios.get(url + '/dte_visit', params, config).then((response) => {
            success();
        }).catch((error) => {
            failure(error.response);
        })
    }
}

export async function getUlincCampaignsData(ulincConfigId, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            getUlincCampaignsData(ulincConfigId, success, failure)
        })
   } else {
        axios.get(url + '/ulinc_campaigns?ulinc_config_id=' + ulincConfigId, config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure();
        })
    }
}

export async function getUlincCampaignsRefreshData(ulincConfigId, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            getUlincCampaignsRefreshData(ulincConfigId, success, failure)
        })
   } else {
        axios.get(url + '/refresh_ulinc_campaigns?ulinc_config_id=' + ulincConfigId, config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure();
        })
    }
}

export async function getJaniumCampaignsContactData(params, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            getJaniumCampaignsContactData(params, success, failure)
        })
   } else {
        axios.get(url + '/janium_campaign_contacts', params, config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure();
        })
    }
}

export async function getCampaignMessages(params, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            getCampaignMessages(params, success, failure)
        })
   } else {
        axios.get(url + '/janium_campaign_steps?janium_campaign_id=' + params.janium_campaign_id + "&is_data_enrichment=" + params.is_data_enrichment, config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure();
        })
    }
}

export async function getCampaignSettings(params, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            getCampaignSettings(params, success, failure)
        })
   } else {
        axios.get(url + '/janium_campaign?janium_campaign_id=' + params.janium_campaign_id, config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure();
        })
    }
}

export async function getCampaignEmails(success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            getCampaignEmails(success, failure)
        })
   } else {
        axios.get(url + '/email_configs', config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure();
        })
    }
}

export async function getDteTypes(success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            getDteTypes(success, failure)
        })
   } else {
        axios.get(url + '/dtes', config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure();
        })
    }
}

export async function getDteSenders(success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            getDteSenders(success, failure)
        })
   } else {
        axios.get(url + '/dte_senders', config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure();
        })
    }
}

export async function getAccountData(success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            getAccountData(success, failure)
        })
   } else {
        axios.get(url + '/account', config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure();
        })
    }
}

export async function getEmailConfig(params, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            getEmailConfig(params, success, failure)
        })
   } else {
        if (params.is_gmail_confirmation) {
            axios.get(url + '/email_config?email_config_id=' + params.email_config_id + '&is_gmail_confirmation=' + params.is_gmail_confirmation, config).then((response) => {
                // console.log("this is my response for account page: ", response);
                success(response);
            }).catch((error) => {
                console.log("this is my error: ", error);
                failure();
            })
        } else {
            axios.get(url + '/email_config?email_config_id=' + params.email_config_id, config).then((response) => {
                // console.log("this is my response for account page: ", response);
                success(response);
            }).catch((error) => {
                console.log("this is my error: ", error);
                failure();
            })
        }
    }
}

export async function getDkimTokens(params, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            getDkimTokens(params, success, failure)
        })
   } else {
        axios.get(url + '/email_dkim_tokens?email_config_id=' + params.email_config_id, config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure();
        })
    }
}

export async function getAccountBillingInfo(success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            getAccountBillingInfo(success, failure)
        })
   } else {
        axios.get(url + '/account_billing_info', config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure();
        })
    }
}

export async function getAddPaymentUrl(success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            getAddPaymentUrl(success, failure)
        })
   } else {
        axios.get(url + '/stripe_add_payment_url', config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure();
        })
    }
}

// PUTS
export async function updateAssignUlincCampaigns(body, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            updateAssignUlincCampaigns(body, success, failure)
        })
   } else {
        axios.put(url + '/ulinc_campaign', body, config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure();
        })
    }
}

export async function updateJaniumCampaignStatus(body, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            updateJaniumCampaignStatus(body, success, failure)
        })
   } else {
        axios.put(url + '/janium_campaign', body, config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure();
        })
    }
}

export async function updateMessages(body, params, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");
    config.headers["Content-Type"] = "application/json";

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            updateMessages(body, params, success, failure) 
        })
   } else {
        axios.put(url + '/janium_campaign_steps?janium_campaign_id=' + params.janium_campaign_id + "&is_data_enrichment=" + params.is_data_enrichment, body, config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure(error.response);
        })
    }
}

export async function updateCampaignSettingChanges(body, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");
    config.headers["Content-Type"] = "application/json";

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            updateCampaignSettingChanges(body, success, failure)
        })
   } else {
        axios.put(url + '/janium_campaign', body, config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure();
        })
    }
}

export async function updateAccountSettings(body, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");
    config.headers["Content-Type"] = "application/json";

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            updateAccountSettings(body, success, failure)
        })
   } else {
        axios.put(url + '/account', body, config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure(error);
        })
    }
}

export async function updateEmailConfig(body, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");
    config.headers["Content-Type"] = "application/json";

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            updateEmailConfig(body, success, failure)
        })
   } else {
        axios.put(url + '/email_config', body, config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure();
        })
    }
}

export async function updateSubscription(params, success, failure) {
    config.headers.Authorization = "Bearer " + Cookies.get("access_token_cookie");
    config.headers["Content-Type"] = "application/json";

    if (isAccessTokenExpired()) {
        refreshTokens(function() {
            updateSubscription(params, success, failure)
        })
   } else {
        axios.put(url + '/set_subscription_price_id?subscription_price_id=' + params.subscriptionPriceId, {}, config).then((response) => {
            // console.log("this is my response for account page: ", response);
            success(response);
        }).catch((error) => {
            console.log("this is my error: ", error);
            failure();
        })
    }
}