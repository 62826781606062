import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Typography, FormControl, FormControlLabel, Switch, TextField, InputLabel, Select, MenuItem, Card, CardActions, CardContent, CardHeader, Button, Modal, Backdrop, Fade }  from '@material-ui/core';
import { EmailIntegrationTable } from "../components";
import * as Api from "../api.js";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  wrapper: {
  },
  divWrapper: {
    borderRadius: "5px",
    maxWidth: 800
  },
  divMinWidth: {
    minWidth: 290
  },
  inputFields: {
    minWidth: 275,
    marginBottom: 15,
    boxshadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    borderRadius: 5,
    display: 'block'
  }, 
  input: {
    minWidth: 275
  },
  ulincInput: {
    width: 120
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxshadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 375
  },
  buttonWrapper: {
    paddingLeft: 30,
    paddingRight: 30
  },
  buttonClasses: {
    background: '#74C69D',
    color: '#FFF',
    minWidth: 125,
    width: '100%',
    transition: '0.1s linear',
    boxshadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    fontSize: 14,
    '&:hover': {
      backgroundColor: '#74C69D',
      transform: 'scale(1.05)'
    }
  },
  buttonClass: {
    width: "fit-content",
    minWidth: 175,
    background: "#74c69d",
    color: "#FFF",
    fontWeight: "400",
    textTransform: "capitalize",
    "&:hover": {
        background: "#63bf91"
    },
  },
  timezone: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: 100
  },
  spinnerStyles: {
    color: "#74c69d"
  }

}));

export default function SettingsPage(props) {
  const classes = useStyles();
  const history = useHistory();

  const [state, setState] = React.useState({
    is_polling_ulinc: false,
    is_sending_emails: false,
    is_sending_li_messages: false,
    is_receiving_dte: false,
    admin_email: "",
    dte_sender_from_email: "",
    dte_sender_id: "",
    dte_id: "",
    dte_hour: "",
    time_zone_code: "",
    testing_ulinc_contact_id: "",
    testing_ulinc_first_name: ""
    
  });

  const [modalText, setModalText] = React.useState({
    title: "Warning",
    bodyOne: "",
    bodyTwo: "",
    buttonText: "",
    inputText: "",
    showInput: true,
    doubleDareForOtherButtons: false,
    eventName: "",
    eventVal: ""
  })

  const [open, setOpen] = React.useState(false);
  const [senders, setSenders] = React.useState([]);
  const [dteTypes, setDteTypes] = React.useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeCheckbox = (event) => {
    if (!state.is_polling_ulinc) {
      setModalText({
        ...modalText,
        title: "Warning!",
        bodyOne: "Your Data Flow from Ulinc is currently turned off and the action you just attempted to perform will have little to no effect on your campaign.",
        bodyTwo: "For this action to affect your Janium campaign(s), please turn on the Data Flow from Ulinc toggle first.",
        buttonText: "Proceed Anyways",
        showInput: false,
        doubleDareForOtherButtons: true,
        eventName: [event.target.name],
        eventVal: event.target.checked 
      })

      handleOpen();
    } else {
      setState({ ...state, 
        [event.target.name]: event.target.checked 
      });
    }
  };
  
  const handleChangeUlincCheckbox = (event) => {
    if (!event.target.checked) {
      setModalText({
        ...modalText,
        title: "Warning",
        bodyOne: "You are about to cut off the data flow from Ulinc for your Janium account. You will not be receiving any new contacts from Ulinc as long as this is turned off.",
        bodyTwo: "To proceed, please type the word STOP below to confirm that you'd like to shut off the data flow from Ulinc:",
        buttonText: "Shut off Data Flow from Ulinc",
        showInput: true,
        doubleDareForOtherButtons: false
      })
    } else {
      setModalText({
        ...modalText,
        title: "Notice!",
        bodyOne: "Your Data Flow from Ulinc is currently turned off and you are attempting to turn it back on. Please make sure that you don't have any other applications polling the Ulinc webhooks as it will interrupt the data flow from the campaign.",
        bodyTwo: "",
        buttonText: "Proceed",
        showInput: false,
        doubleDareForOtherButtons: false
      })
    }

    handleOpen();
  };
  
  const handleShutOffUlincPolling = (event) => {
    if (modalText.inputText === "STOP") {
      setState({
        ...state,
        is_polling_ulinc: false,
        is_sending_emails: false,
        is_sending_li_messages: false,
        is_receiving_dte: false
      })
    }

    setModalText({
      ...modalText,
      inputText: ""
    })

    handleClose();
  }

  const handleTurnOnUlincPolling = (event) => {
    setState({
      ...state,
      is_polling_ulinc: true
    })

    handleClose();
  }

  const handleProceedAnyway = () => {
    let stateName = modalText.eventName[0];
    let value = modalText.eventVal;

    setState({
      ...state,
      [stateName]: value
    })

    handleClose();
  }

  const handleTextChange = (event) => {
    setState({
        ...state,
        [event.target.name]: event.target.value
    })
  };

  const handleModalTextChange = (event) => {

    setModalText({
        ...modalText,
        [event.target.name]: event.target.value
    })
  };

  React.useEffect(() => {
    props.siteLoader(true);

    Api.getAccountData(getAccountDataSuccess, getAccountDataFailure)
  }, [])

  const getAccountDataSuccess = (response) => {
    response.data.dte_cc_list = response.data.dte_cc_list.toString().replace(/,/g, ', ');
    response.data.dte_bcc_list = response.data.dte_bcc_list.toString().replace(/,/g, ', ');
    setState(response.data);

    props.siteLoader(false);
  }

  const getAccountDataFailure = (response) => {
    console.log("setting page email req fail: ", response);
  }

  React.useEffect(() => {
    Api.getDteSenders(getDteSendersSuccess, getDteSendersFailure)
  }, [])

  const getDteSendersSuccess = (response) => {
    setSenders(response.data);
  }

  const getDteSendersFailure = (response) => {
    console.log("setting page email req fail: ", response);
  }

  React.useEffect(() => {
    Api.getDteTypes(getDteTypesSuccess, getDteTypesFailure)
  }, [])

  const getDteTypesSuccess = (response) => {
    setDteTypes(response.data);
  }

  const getDteTypesFailure = (response) => {
    console.log("setting page email req fail: ", response);
  }

  const handleUpdateAccountSettings = () => {
    let element = document.getElementById("buttonLoading");
    element.classList.remove("d-none");

    let ccArray = state.dte_cc_list;
    ccArray = ccArray.split(", ");

    let bccArray = state.dte_bcc_list;
    bccArray = bccArray.split(", ")

    let accountSettings = {
      "dte_id": state.dte_id,
      "dte_sender_id": state.dte_sender_id,
      "is_polling_ulinc": state.is_polling_ulinc,
      "is_receiving_dte": state.is_receiving_dte,
      "is_sending_emails": state.is_sending_emails,
      "is_sending_li_messages": state.is_sending_li_messages,
      "time_zone_code": state.time_zone_code,
      "dte_cc_list": ccArray,
      "dte_bcc_list": bccArray,
      "dte_hour": state.dte_hour,
      "admin_email": state.admin_email,
      "testing_ulinc_contact_id": state.testing_ulinc_contact_id,
      "testing_ulinc_first_name": state.testing_ulinc_first_name
    }

    Api.updateAccountSettings(accountSettings, updateAccountSettingsSuccess, updateAccountSettingsFailure)
  }

  const updateAccountSettingsSuccess = (response) => {
    let element = document.getElementById("buttonLoading");
    element.classList.add("d-none");
  }

  const updateAccountSettingsFailure = (response) => {
    console.log("setting page email req fail: ", response);
  }

  const redirectToEmailSettings = (emailConfigId) => {
    history.push("/email-settings/?email-config-id=" + emailConfigId);
  }

  return (
  	<div className="h-100 mb-5 px-5">
      <br />
      <EmailIntegrationTable emailSettingsRedirect={redirectToEmailSettings} />
      <br />
      <div className={classes.divWrapper + " d-flex flex-column mt-3 pl-5 p-3 tableBoxShadow mx-auto"}>
          <Typography variant="h6" gutterBottom>
              Account Settings
          </Typography>
          <div className="pl-2 d-flex flex-column">
            <div className="d-flex flex-row align-items-center">
              <div className={classes.divMinWidth + " d-flex flex-column mr-5"}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.is_polling_ulinc}
                      onChange={handleChangeUlincCheckbox}
                      name="is_polling_ulinc"
                      color="primary"
                    />
                  }
                  label={<Typography className={classes.switchText}>Data Flow From Ulinc</Typography>}
                /> 
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.is_sending_emails}
                      onChange={handleChangeCheckbox}
                      name="is_sending_emails"
                      color="primary"
                    />
                  }
                  label={<Typography className={classes.switchText}>Email Sending</Typography>}
                /> 
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.is_sending_li_messages}
                      onChange={handleChangeCheckbox}
                      name="is_sending_li_messages"
                      color="primary"
                    />
                  }
                  label={<Typography className={classes.switchText}>LinkedIn Message Sending</Typography>}
                /> 
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.is_receiving_dte}
                      onChange={handleChangeCheckbox}
                      name="is_receiving_dte"
                      color="primary"
                    />
                  }
                  label={<Typography className={classes.switchText}>Receive Daily Email</Typography>}
                /> 
              </div>
              <div className="d-flex flex-column pl-5">
                <Typography variant="subtitle1" gutterBottom>
                  Admin Access
                </Typography>
                <div className="pl-2">
                  <TextField 
                    className={classes.inputFields} 
                    onChange={handleTextChange} 
                    size="small"
                    label="Admin Email" 
                    type="text" 
                    variant="outlined"
                    name="admin_email" 
                    value={state.admin_email} 
                    InputProps={{
                        className: classes.input,
                    }}
                  />
                </div>

                <Typography variant="subtitle1" gutterBottom>
                    Default Ulinc Test Message Contact
                </Typography>
                <div className="d-flex justify-content-between pl-2">
                  <TextField 
                    onChange={handleTextChange} 
                    size="small"
                    label="First Name" 
                    type="text" 
                    variant="outlined"
                    name="testing_ulinc_first_name" 
                    value={state.testing_ulinc_first_name} 
                    className={classes.ulincInput}
                    InputProps={{
                        className: classes.ulincInput,
                    }}
                  />
                  <TextField 
                    onChange={handleTextChange} 
                    size="small"
                    label="Ulinc ID" 
                    type="text" 
                    variant="outlined"
                    name="testing_ulinc_contact_id" 
                    value={state.testing_ulinc_contact_id} 
                    className={classes.ulincInput}
                    InputProps={{
                        className: classes.ulincInput,
                    }}
                  />
                </div>
              </div>
            </div>
            {
              state.is_receiving_dte ?
                <div className="d-flex flex-row align-items-center">
                  <div className={classes.divMinWidth + " d-flex flex-column pl-5"}>
                    <FormControl className={classes.inputFields}>
                        <InputLabel id="dteEmailSender">Daily Email Sender</InputLabel>
                        <Select
                            labelId="dteEmailSender"
                            name="dte_sender_id"
                            value={state.dte_sender_id}
                            onChange={handleTextChange}
                        >
                            {
                                senders.map((dteSender, i) => {
                                    return (
                                        <MenuItem key={i} value={dteSender.dte_sender_id}>{dteSender.dte_sender_full_name + " <" + dteSender.dte_sender_from_email + ">"}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>

                    <FormControl className={classes.inputFields}>
                        <InputLabel id="dteEmailType">Daily Email Type</InputLabel>
                        <Select
                            labelId="dteEmailType"
                            name="dte_id"
                            value={state.dte_id}
                            onChange={handleTextChange}
                        >
                            {
                                dteTypes.map((dteType, i) => {
                                    return (
                                        <MenuItem key={i} value={dteType.dte_id}>{dteType.dte_name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    <div className="d-flex position-relative">
                      <FormControl className={classes.inputFields}>
                          <InputLabel id="dteEmailTime">Daily Email Schedule (Time)</InputLabel>
                          <Select
                              labelId="dteEmailTime"
                              name="dte_hour"
                              value={state.dte_hour}
                              onChange={handleTextChange}
                          >
                            <MenuItem value="1">12:00 am</MenuItem>
                            <MenuItem value="2">1:00 am</MenuItem>
                            <MenuItem value="3">2:00 am</MenuItem>
                            <MenuItem value="4">3:00 am</MenuItem>
                            <MenuItem value="5">4:00 am</MenuItem>
                            <MenuItem value="6">5:00 am</MenuItem>
                            <MenuItem value="7">6:00 am</MenuItem>
                            <MenuItem value="8">7:00 am</MenuItem>
                            <MenuItem value="9">8:00 am</MenuItem>
                            <MenuItem value="10">9:00 am</MenuItem>        
                            <MenuItem value="11">10:00 am</MenuItem>
                            <MenuItem value="12">11:00 am</MenuItem>
                            <MenuItem value="13">12:00 pm</MenuItem>
                            <MenuItem value="14">1:00 pm</MenuItem>
                            <MenuItem value="15">2:00 pm</MenuItem>
                            <MenuItem value="16">3:00 pm</MenuItem>
                            <MenuItem value="17">4:00 pm</MenuItem>
                            <MenuItem value="18">5:00 pm</MenuItem>
                            <MenuItem value="19">6:00 pm</MenuItem>
                            <MenuItem value="20">7:00 pm</MenuItem>
                            <MenuItem value="21">8:00 pm</MenuItem>
                            <MenuItem value="22">9:00 pm</MenuItem>
                            <MenuItem value="23">10:00 pm</MenuItem>
                            <MenuItem value="24">11:00 pm</MenuItem>
                          </Select>
                      </FormControl>
                      <div className={classes.timezone}>{state.time_zone_code} Time</div>
                    </div>
                    
                  </div>
                  <div className="d-flex flex-column align-self-end pl-2">
                    <TextField 
                      className={classes.inputFields} 
                      onChange={handleTextChange} 
                      size="small"
                      label="Daily Email CC(s)" 
                      type="text" 
                      variant="outlined"
                      name="dte_cc_list" 
                      value={state.dte_cc_list} 
                      InputProps={{
                          className: classes.input,
                      }}
                    />
                    <TextField 
                      className={classes.inputFields} 
                      onChange={handleTextChange} 
                      size="small"
                      label="Daily Email BCC(s)" 
                      type="text" 
                      variant="outlined"
                      name="dte_bcc_list" 
                      value={state.dte_bcc_list} 
                      InputProps={{
                          className: classes.input,
                      }}
                    />
                  </div>

                </div>

              :
              <div></div>
              //do nothing
            }
          </div>

          <div className="d-flex align-items-center justify-content-center">
            <Button onClick={handleUpdateAccountSettings} variant="contained" color="primary" size="small" className={classes.buttonClass + " mr-3 mt-3"}>
                Save
            </Button>
            <div class={classes.spinnerStyles + " spinner-border d-none"} id="buttonLoading" role="status">
                <span class="sr-only">Loading...</span>
            </div>
          </div>
          
          
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Card variant="outlined">
              <CardHeader className="text-center" titleTypographyProps={{variant:'h5'}} title={modalText.title} />
              <CardContent className="d-flex flex-column align-items-center">
                <Typography variant="string" gutterBottom align="center">
                  {modalText.bodyOne}
                </Typography>
                <Typography variant="string" gutterBottom align="center">
                  {modalText.bodyTwo}
                </Typography>
                {
                  modalText.showInput ? 
                    <TextField 
                      className={classes.inputFields + " mx-auto mt-2"} 
                      onChange={handleModalTextChange} 
                      type="text" 
                      variant="outlined"
                      name="inputText" 
                      placeholder="Type Here.."
                      value={modalText.inputText} 
                      InputProps={{
                          className: classes.input,
                      }}
                    />
                    :
                    <div></div>
                }
                


              </CardContent>
              <CardActions className={classes.buttonWrapper}>
                {
                  modalText.showInput ? 
                    <Button variant="contained" className={classes.buttonClasses + " mb-2"} onClick={handleShutOffUlincPolling}>{modalText.buttonText}</Button>
                  :
                  (
                    modalText.doubleDareForOtherButtons ?
                      <Button variant="contained" className={classes.buttonClasses + " mb-2"} onClick={handleProceedAnyway}>{modalText.buttonText}</Button>
                    :
                      <Button variant="contained" className={classes.buttonClasses + " mb-2"} onClick={handleTurnOnUlincPolling}>{modalText.buttonText}</Button>
                  )
                    
                }
                
              </CardActions>
            </Card>
          </div>
        </Fade>
      </Modal>


    </div>
  );
}