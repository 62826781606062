import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, List, ListItem, ListItemIcon, ListItemText, Card, CardActions, CardContent, CardHeader, Button, Typography, Modal, Backdrop, Fade }  from '@material-ui/core';
import greenCheck from '../assets/greenCheck.png';
import AmericanExpress from '../assets/AmericanExpress.png';
import Discover from '../assets/Discover.png';
import Mastercard from '../assets/Mastercard.png';
import Visa from '../assets/Visa.png';
import GenericCard from '../assets/GenericCard.png';
import EditIcon from '@material-ui/icons/Edit';
import * as Api from "../api.js";
import {BrowserRouter as Router, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  containerStyles: {
    maxWidth: 1350
  },
  wrapper: {
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    // boxShadow: theme.shadows[5],
    boxShadow: '1px 1px 3px 0 rgb(0 0 0 / 49%)',
    padding: theme.spacing(1, 0, 3),
    width: 345
  },
  paperHeader: {
    marginTop: 10,
    marginBottom: 0,
    fontSize: 30,
    lineHeight: '46px',
    fontWeight: 400,
  },
  cardContent: {
    padding: '16px 2px 16px 0'
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  buttonWrapper: {
    paddingLeft: 30,
    paddingRight: 30
  },
  buttonClasses: {
    background: '#74C69D',
    color: '#FFF',
    minWidth: 175,
    width: 'fit-content',
    transition: '0.1s linear',
    boxshadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    fontSize: 16,
    '&:hover': {
      backgroundColor: '#74C69D',
      transform: 'scale(1.05)'
    },
  },
  disabledButton: {
    backgroundColor: '#6f7072 !important',
    color: "#FFF !important"
  },
  buttonClass: {
    width: "fit-content",
    background: "#74c69d",
    color: "#FFF",
    fontWeight: "400",
    textTransform: "capitalize",
    "&:hover": {
        background: "#63bf91"
    },
  },
  spinnerStyles: {
    color: "#74c69d"
  },
  price: {
    fontSize: 30
  },
  priceSup: {
    fontSize: 16,
    color: '#818181'
  },
  icon: {
    minWidth: 40
  },
  listItem: {
    padding: '2px 8px',
    alignItems: 'flex-start'
  },
  listItemText: {
    margin: 0,
    padding: 0
  },
  checkMark: {
    height: 25
  },
  textBox: {
    margin: 'auto',
    padding: 18,
    background: '#e6e6e6',
    maxWidth: 620,
    textAlign: 'center',
    border: '1px solid #d8d8d8',
    borderRadius: 5
  },
  textBoxHyperlink: {
    color: '#0A369D !important',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline !important'
    }
  },
  seatsStyles: {
    top: 0,
    left: 0,
    color: '#FFF',
    background: '#73c69d',
    padding: '5px 21px',
    borderBottomRightRadius: 5
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxshadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 550
  },
  modalButtonWrapper: {
    paddingLeft: 30,
    paddingRight: 30,
    display: 'flex',
    justifyContent: 'center',
    width: 'fit-content',
    margin: '0 auto'
  },
  modalButtonClasses: {
    background: '#74C69D',
    color: '#FFF',
    minWidth: 125,
    width: 'fit-content',
    transition: '0.1s linear',
    boxshadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    fontSize: 14,
    '&:hover': {
      backgroundColor: '#74C69D',
      transform: 'scale(1.05)'
    }
  },
  modalSecondaryButtonClasses: {
    width: 'fit-content',
    whiteSpace: 'nowrap',
    minWidth: 180
  },
  paymentMethodWrapper: {
    maxWidth: 300,
    margin: '0 auto'
  },
  paymentMethodHeader: {
    color: "#6f7072",
    fontWeight: '600'
  },
  cardImg: {
    height: 60,
    width: 'auto'
  },
  cardNum: {
    fontSize: 22,
    fontWeight: '500',
    whiteSpace: 'nowrap'
  },
  editIcon: {
    position: 'absolute',
    top: 2,
    right: 2,
    cursor: 'pointer',
    color: '#6f7072'
  }

}));

export default function SubscriptionsPage(props) {
  const classes = useStyles();
  const history = useHistory();

  let priceId = "";

  const [billingInfo, setBillingInfo] = React.useState({
    "is_admin_paid": false,
    "has_payment_method": false,
    "current_subscription_price": {
      "current_subscription_price_id": "",
      "current_subscription_price_name": "",
      "current_subscription_price": 0
    },
    "seats": 0,
    "payment_method": {
        "payment_method_id": "",
        "payment_method_last_4": "",
        "payment_method_holder_name": "",
        "payment_method_issuer": ""
    },
    "subscription_prices": [
      {
        "subscription_price_name": "",
        "subscription_price_id": "",
        "subscription_price": ""
      },
      {
        "subscription_price_name": "",
        "subscription_price_id": "",
        "subscription_price": ""
      }
    ]
  })

  const [buttonText, setButtonText] = React.useState({
    buttonOne: "Select Plan",
    buttonTwo: "Select Plan"
  })

  const [creditCards, setCreditCards] = React.useState(
    {
      "AmericanExpress": AmericanExpress,
      "Visa": Visa,
      "Mastercard": Mastercard,
      "Discover": Discover
    }
  )

  const [boxText, setBoxText] = React.useState({
    shownTextFirst: "You have not added a payment method, please click ",
    shownTextSecond: " to add one.",
    rerouteFunction: function() {
      Api.getAddPaymentUrl(getAddPaymentUrlSuccess, getAddPaymentUrlFailure)
    }
  })

  const [modalText, setModalText] = React.useState({
    title: "Subscriptions",
    bodyOne: "",
    bodyTwo: "",
    buttonText: "",
    handleModalButtonClick: function(priceId) {
    }
  })

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const routeChange = (path) => { 
    history.push(path);
  }

  const getAddPaymentUrlSuccess = (response) => {
    window.open(response.data.stripe_add_payment_url, '_blank');
  }

  const getAddPaymentUrlFailure = () => {
    console.log("could not pull data");
  }

  React.useEffect(() => {
    props.siteLoader(true);

    Api.getAccountBillingInfo(getAccountBillingInfoSuccess, getAccountBillingInfoFailure)
  }, [])

  const getAccountBillingInfoSuccess = (response) => {
    setBillingInfo(response.data);

    if (response.data.current_subscription_price.current_subscription_price_id === response.data.subscription_prices[0].subscription_price_id) {
      setButtonText({
        ...buttonText,
        buttonOne: "Current Plan",
        buttonTwo: "Switch to Plan"
      })

      setModalText({
        ...modalText,
        title: "Subscriptions",
        buttonText: "Switch Plan",
        bodyTwo: "",
        bodyThree: "",
        showSecondButton: false,
        email: ""
      })
    } else if (response.data.current_subscription_price.current_subscription_price_id === response.data.subscription_prices[1].subscription_price_id) {
      setButtonText({
        ...buttonText,
        buttonOne: "Switch to Plan",
        buttonTwo: "Current Plan"
      })

      setModalText({
        ...modalText,
        title: "Subscriptions",
        buttonText: "Switch Plan",
        bodyTwo: "",
        bodyThree: "",
        showSecondButton: false,
        email: ""
      })
    } else {
      // User hasnt selected a plan yet
      setButtonText({
        ...buttonText,
        buttonOne: "Select Plan",
        buttonTwo: "Select Plan"
      })

      setModalText({
        ...modalText,
        title: "Subscriptions",
        buttonText: "Confirm",
        bodyTwo: "",
        bodyThree: "",
        showSecondButton: false,
        email: ""
      })
    }

    props.siteLoader(false);
  }

  const getAccountBillingInfoFailure = (response) => {
    console.log("setting page email req fail: ", response);
  }

  const subscribeToPlanClickHandler = () => {
    let element = document.getElementById("handleModalButtonClickLoader");
    element.classList.remove("d-none");

    let params = {
      subscriptionPriceId: priceId
    }
    Api.updateSubscription(params, subscribeToPlanClickHandlerSuccess, subscribeToPlanClickHandlerFailure)
  }

  const subscribeToPlanClickHandlerSuccess = (response) => {
    setBillingInfo(response.data);

    // if they have no accounts attatched to the subscription type
    if (billingInfo.seats === 0) {
      setModalText({
        ...modalText,
        title: "Congratulations",
        bodyOne: "Would you like to setup an account under this subscription plan?",
        buttonText: "Setup Account",
        bodyTwo: "",
        bodyThree: "",
        showSecondButton: false,
        email: "",
        handleModalButtonClick: goToAccountPage
      })

      if (response.data.current_subscription_price.current_subscription_price_id === response.data.subscription_prices[0].subscription_price_id) {
        setButtonText({
          ...buttonText,
          buttonOne: "Current Plan",
          buttonTwo: "Switch to Plan"
        })
      } else if (response.data.current_subscription_price.current_subscription_price_id === response.data.subscription_prices[1].subscription_price_id) {
        setButtonText({
          ...buttonText,
          buttonOne: "Switch to Plan",
          buttonTwo: "Current Plan"
        })
      } 

    } else {
      if (response.data.current_subscription_price.current_subscription_price_id === response.data.subscription_prices[0].subscription_price_id) {
        setButtonText({
          ...buttonText,
          buttonOne: "Current Plan",
          buttonTwo: "Switch to Plan"
        })
  
        setModalText({
          ...modalText,
          title: "Subscriptions",
          buttonText: "Switch Plan",
          bodyTwo: "",
          bodyThree: "",
          email: "",
          showSecondButton: false
        })
      } else if (response.data.current_subscription_price.current_subscription_price_id === response.data.subscription_prices[1].subscription_price_id) {
        setButtonText({
          ...buttonText,
          buttonOne: "Switch to Plan",
          buttonTwo: "Current Plan"
        })
  
        setModalText({
          ...modalText,
          title: "Subscriptions",
          buttonText: "Switch Plan",
          bodyTwo: "",
          bodyThree: "",
          email: "",
          showSecondButton: false
        })
      } 
      handleClose()
    }
    console.log('success', response);

    let element = document.getElementById("handleModalButtonClickLoader");
    element.classList.add("d-none");
  }

  const subscribeToPlanClickHandlerFailure = (response) => {
    console.log("fail: ", response);
  }

 const setPriceId = (priceIdVal, planName) => {
  setModalText({
    ...modalText,
    title: "Subscriptions",
    bodyOne: "You have selected the " + planName + " plan, would you like to continue?",
    buttonText: "Confirm",
    bodyTwo: "",
    bodyThree: "",
    showSecondButton: false,
    email: "",
    handleModalButtonClick: subscribeToPlanClickHandler
  })

  priceId = priceIdVal;

  handleOpen()
 }

 const handleOpenCancelSubscriptions = (priceIdVal, planName) => {
  setModalText({
    ...modalText,
    title: "Cancel Janium Subscription",
    bodyOne: "Is there something we can improve that will help you to stay with Janium? Let us know by sending an email at ",
    bodyTwo: "Your account data will be permanently deleted from Janium's servers and can't be restored.",
    bodyThree: "If you still want to cancel your subscription, click 'Cancel Subscription' button below. If you cancel, your subscription will expire on the next 4th of the month, and you won't be billed for renewal.",
    email: "support@janium.io",
    buttonText: "Keep Subscription",
    showSecondButton: true,
    handleModalButtonClick: handleClose,
    handleModalButtonTwoClick: cancleSubscriptionClickHandler
  })

  handleOpen()
 }

const cancleSubscriptionClickHandler = () => {
  let element = document.getElementById("handleModalButtonClickLoader");
  element.classList.remove("d-none");

  Api.cancelSubscription(cancelSubscriptionSuccess, cancelSubscriptionFailure)
}

const cancelSubscriptionSuccess = (response) => {
  setBillingInfo(response.data);

  let element = document.getElementById("handleModalButtonClickLoader");
  element.classList.add("d-none");

  if (response.data.current_subscription_price.current_subscription_price_id === response.data.subscription_prices[0].subscription_price_id) {
    setButtonText({
      ...buttonText,
      buttonOne: "Current Plan",
      buttonTwo: "Switch to Plan"
    })

    setModalText({
      ...modalText,
      title: "Subscriptions",
      buttonText: "Switch Plan",
      bodyTwo: "",
      bodyThree: "",
      showSecondButton: false,
      email: ""
    })
  } else if (response.data.current_subscription_price.current_subscription_price_id === response.data.subscription_prices[1].subscription_price_id) {
    setButtonText({
      ...buttonText,
      buttonOne: "Switch to Plan",
      buttonTwo: "Current Plan"
    })

    setModalText({
      ...modalText,
      title: "Subscriptions",
      buttonText: "Switch Plan",
      bodyTwo: "",
      bodyThree: "",
      showSecondButton: false,
      email: ""
    })
  } else {
    // User hasnt selected a plan yet
    setButtonText({
      ...buttonText,
      buttonOne: "Select Plan",
      buttonTwo: "Select Plan"
    })

    setModalText({
      ...modalText,
      title: "Subscriptions",
      buttonText: "Confirm",
      bodyTwo: "",
      bodyThree: "",
      showSecondButton: false,
      email: ""
    })
  }
  
  handleClose()
}

const cancelSubscriptionFailure = () => {
  
}

 const goToAccountPage = () => {
  routeChange('/accounts?modal-open=true')
 }

 const checkCreditCards = (card) => {
  if (card in creditCards) {
    return creditCards[card];
  } else {
    return GenericCard;
  }
 }

 const obfuscateCardNum = (cardNum) => {
   return "**** " + cardNum
 }

  return (
    <div className={classes.containerStyles + " container-fluid h-100 mb-5 px-5"}>
      {
        billingInfo.has_payment_method ?
        <div className={classes.paymentMethodWrapper + " d-flex justify-content-center mt-5 flex-column position-relative"}>
          <EditIcon className={classes.editIcon} onClick={boxText.rerouteFunction} />
          <Typography variant="h6" className={classes.paymentMethodHeader}>Payment Method</Typography>
          <div className="d-flex align-items-center">
            <img className={classes.cardImg} src={checkCreditCards(billingInfo.payment_method.payment_method_issuer)} alt="credit card" />
            <span className={classes.cardNum}>{obfuscateCardNum(billingInfo.payment_method.payment_method_last_4)} ({billingInfo.payment_method.payment_method_expiration})</span>
          </div>
        </div>
      :
      <div className={classes.textBox + " mt-5 tableBoxShadow"}>
        {boxText.shownTextFirst} <button className={classes.textBoxHyperlink + " anchor"} onClick={boxText.rerouteFunction}>here</button> {boxText.shownTextSecond}
      </div>
      }

      <div className="row py-5">
        <div className="col-4 d-flex justify-content-center px-4">
          <Card variant="outlined" className={classes.paper + " position-relative"}>
            <div className={(billingInfo.current_subscription_price.current_subscription_price_id === billingInfo.subscription_prices[0].subscription_price_id) ? classes.seatsStyles + " position-absolute" : " d-none"}>Seats: {billingInfo.seats}</div>
            <CardHeader 
              className="text-center mb-0 pb-0"
              titleTypographyProps={{variant:'h3'}} 
              title={billingInfo.subscription_prices[0].subscription_price_name}
              classes={{
                title: classes.paperHeader
              }} 
            />
            <CardActions className={classes.buttonWrapper + " d-flex flex-column"}>
                <div className={classes.price}>
                ${billingInfo.subscription_prices[0].subscription_price} <sup className={classes.priceSup}>/ month</sup>
                </div>
                <Button 
                  variant="contained" 
                  className={classes.buttonClasses + " mb-2 mt-2"} 
                  disabled={(billingInfo.current_subscription_price.current_subscription_price_id === billingInfo.subscription_prices[0].subscription_price_id || !billingInfo.has_payment_method) ? true : false} 
                  // onClick={() => subscribeToPlanClickHandler(billingInfo.subscription_prices[0].subscription_price_id)} 
                  onClick={() => setPriceId(billingInfo.subscription_prices[0].subscription_price_id, billingInfo.subscription_prices[0].subscription_price_name)}
                  classes={{ disabled: classes.disabledButton }}
                >
                  {buttonText.buttonOne}
                </Button>
            </CardActions>
            <CardContent className={classes.cardContent + " d-flex flex-column align-items-center px-3"}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <div>
                      <List>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>Direct Ulinc Integration & Seamless Data Flow</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>O365, Gmail, & Other Integrations</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>Up to 250+ Email Follow-ups to Connections Per Day</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>Coordinated LinkedIn + Email Outreach</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>Email Validation & 99%+ Delivery Rates</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>Handwritten-looking Emails for Higher Repsonse Rates</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>Reply in Thread Emails</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>Manual Welcome Message Capability</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>Daily Task/Summary Email</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>New Connection Qualification</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>End of Campaign Phone Outreach Report</Typography>}
                          />
                        </ListItem>
                      </List>
                    </div>
                  </Grid>
                </Grid>
            </CardContent>
          </Card>
        </div>

        <div className="col-4 d-flex justify-content-center px-4">
          <Card variant="outlined" className={classes.paper + " position-relative"}>
            <div className={(billingInfo.current_subscription_price.current_subscription_price_id === billingInfo.subscription_prices[1].subscription_price_id) ? classes.seatsStyles + " position-absolute" : " d-none"}>Seats: {billingInfo.seats}</div>
            <CardHeader 
              className="text-center mb-0 pb-0"
              titleTypographyProps={{variant:'h3'}} 
              title={billingInfo.subscription_prices[1].subscription_price_name} 
              classes={{
                title: classes.paperHeader
              }} 
            />
            <CardActions className={classes.buttonWrapper + " d-flex flex-column"}>
                <div className={classes.price}>
                  ${billingInfo.subscription_prices[1].subscription_price} <sup className={classes.priceSup}>/ month</sup>
                </div>
                <Button 
                  variant="contained" 
                  className={classes.buttonClasses + " mb-2 mt-2"} 
                  disabled={(billingInfo.current_subscription_price.current_subscription_price_id === billingInfo.subscription_prices[1].subscription_price_id || !billingInfo.has_payment_method) ? true : false} 
                  // onClick={() => subscribeToPlanClickHandler(billingInfo.subscription_prices[1].subscription_price_id)} 
                  onClick={() => setPriceId(billingInfo.subscription_prices[1].subscription_price_id, billingInfo.subscription_prices[1].subscription_price_name)}
                  classes={{ disabled: classes.disabledButton }}
                >
                  {buttonText.buttonTwo}
                </Button>
            </CardActions>
            <CardContent className={classes.cardContent + " d-flex flex-column align-items-center px-3"}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <div>
                      <List>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>Direct Ulinc Integration & Seamless Data Flow</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>O365, Gmail, & Other Integrations</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>Up to 250+ Email Follow-ups to Connections Per Day</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>Coordinated LinkedIn + Email Outreach</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>Email Validation & 99%+ Delivery Rates</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>Handwritten-looking Emails for Higher Repsonse Rates</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>Reply in Thread Emails</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>Manual Welcome Message Capability</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>Daily Task/Summary Email</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>New Connection Qualification</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>End of Campaign Phone Outreach Report</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>Auto Data Mining for Non-Connected Contacts (60-80% of total Ulinc Outreach)</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>Seamless & Coordinated Email Outreach to Data Mined Contacts</Typography>}
                          />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.icon}>
                            <img className={classes.checkMark} src={greenCheck} alt="Green Checkmark" />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemText}
                            disableTypography
                            primary={<Typography type="body2" style={{ fontSize: 16, lineHeight: '26px' }}>Work Email Prioritization</Typography>}
                          />
                        </ListItem>
                      </List>
                    </div>
                  </Grid>
                </Grid>
            </CardContent>
            
          </Card>
        </div>

        <div className="col-4 d-flex justify-content-center px-4">
          <Card variant="outlined" className={classes.paper}>
            <CardHeader 
              className="text-center mb-0 pb-0"
              titleTypographyProps={{variant:'h3'}} 
              title="Janium Action" 
              classes={{
                title: classes.paperHeader
              }} 
            />
            <CardActions className={classes.buttonWrapper + " d-flex flex-column"}>
                <div className={classes.price}>
                  Beta
                </div>
                <Button variant="contained" className={classes.buttonClasses + " mb-2 mt-2"} >Talk To Sales</Button>
            </CardActions>
            <CardContent className="d-flex flex-column align-items-center">
            </CardContent>
          </Card>
        </div>
        {
        billingInfo.current_subscription_price.current_subscription_price_id ?
          <div className="col-12 text-center mt-5">
            <button className={classes.textBoxHyperlink + " anchor"} onClick={handleOpenCancelSubscriptions}>Cancel Subscription</button>
          </div>
        :
          <div></div>
        }
      </div>


      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.modalPaper}>
            <Card variant="outlined">
              <CardHeader className="text-center" titleTypographyProps={{variant:'h5'}} title={modalText.title} />
              <CardContent className="d-flex flex-column align-items-center">
                <Typography variant="string" gutterBottom align="center">
                  {modalText.bodyOne} 
                  {
                    modalText.email ?
                    <a href={"mailto:" + modalText.email}>{modalText.email}</a>
                    :
                    <div></div>
                  }
                </Typography>
                <Typography variant="string" gutterBottom align="center" className="red">
                  {modalText.bodyTwo} 
                </Typography>
                <Typography variant="string" gutterBottom align="center">
                  {modalText.bodyThree}
                </Typography>


              </CardContent>
              <CardActions className={classes.modalButtonWrapper}>
                {
                  modalText.showSecondButton ?
                    <Button variant="contained" className={classes.modalSecondaryButtonClasses + " mb-2"} onClick={modalText.handleModalButtonTwoClick}>Cancel Subscription</Button>
                  :
                    <div></div>
                }
                <div className="d-inline-block w-100 text-center text-nowrap">
                  <Button variant="contained" className={classes.modalButtonClasses + " mb-2"} onClick={modalText.handleModalButtonClick}>{modalText.buttonText}</Button>
                  <div className={classes.spinnerStyles + " spinner-border d-none ml-3"} id="handleModalButtonClickLoader" role="status">
                      <span className="sr-only">Loading...</span>
                  </div>
                </div>  
              </CardActions>
            </Card>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}