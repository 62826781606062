import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActions, CardContent, CardHeader, Button, Typography, Divider, Modal, Backdrop, Fade, TextField } from '@material-ui/core';
import { AccountList } from "..";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import CloseIcon from '@material-ui/icons/Close';
import * as Api from "../../api.js";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    maxWidth: "calc(100vw - 35%)"
  },
  cardHeader: {
    background: "#74C69D",
    boxshadow: "-3px -5px 6px 9px rgb(0 0 0 / 20%)" 
  },
  cardTitle: {
    color: "#FFF",
    fontWeight: 600
  },
  titleSpan: {
    position: 'absolute',
    bottom: '-2px'
  },
  cardHeaderTitle: {
    color: "#FFF",
    fontWeight: 600,
    verticalAlign: 'center'
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  actionButton: {
    color: "#0A369D"
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxshadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 450,
    fontSize: 14
  },
  inputFieldWrapper: {
    marginBottom: 15
  },
  inputFields: {
    minWidth: 300,
    boxshadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    borderRadius: 5,
    fontSize: 14
  },
  buttonWrapper: {
    paddingLeft: 30,
    paddingRight: 30
  },
  buttonClasses: {
    background: '#74C69D',
    color: '#FFF',
    minWidth: 125,
    width: 'fit-content',
    transition: '0.1s linear',
    boxshadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    '&:hover': {
      backgroundColor: '#74C69D',
      transform: 'scale(1.05)'
    }
  },
  requiredFields: {
    fontSize: 10,
    marginLeft: 20
  },
  checkboxText: {
    lineHeight: '1.1',
    fontSize: 14,
    textAlign: 'center'
  },
  spinnerStyles: {
    color: "#74c69d"
  },
  closeIcon: {
    position: 'absolute',
    right: 2,
    top: 2,
    color: '#81868f',
    cursor: 'pointer'
  }
}));

export default function AccountsCard(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [newUlincConfigData, setNewUlincConfigData] = React.useState({
    "ulinc_username": "",
    "ulinc_password": "",
    "ulinc_li_email": "",
    "inputErrorMsgUlincEmail": "",
    "inputErrorMsgUlincPassword": "",
    "inputErrorMsgLiEmail": "",
    "newUlincConfigId": ""
  });

  const [confirmModalText, setConfirmModalText] = React.useState({
    showForm: true,
    modalTitle: "Payment Notification",
    modalText: function(plan, paymentAmount) {
      return "Please note that by connecting this LinkedIn account via Ulinc to Janium, you will be charged $" + paymentAmount + " per month for the " + plan + "."
    },
    secondParagraphText: " By continuing, you will be billed a prorated amount for your subscription from now until the 5th of the month, which will be your subscription renewal date.",
    buttonText: "Continue",
    buttonFnc: function() {

    }
  })

  const handleOpen = () => {
    setConfirmModalText({
      ...confirmModalText,
      showForm: props.billingInfo.is_admin_paid ? true : false
    })

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    setNewUlincConfigData({
      ...newUlincConfigData,
      "ulinc_username": "",
      "ulinc_password": "",
      "ulinc_li_email": ""
    })

    setConfirmModalText({
      ...confirmModalText,
      showForm: false
    })
  };

  const handleTextChange = (event) => {
    setNewUlincConfigData({
      ...newUlincConfigData,
      [event.target.name]: event.target.value,
      inputErrorMsgUlincEmail: "",
      inputErrorMsgUlincPassword: "",
      inputErrorMsgLiEmail: ""
    });
  }

  React.useEffect(() => {
    var url_string = window.location.href; 
    var url = new URL(url_string);
    var modalOpen = url.searchParams.get("modal-open");

    if (modalOpen === "true") {
      handleOpen()
    }
  })

  const handleNewUlincConfigClick = () => {
    let element = document.getElementById("handleNewUlincConfigClickLoader");
    element.classList.remove("d-none");

    if (!validateNotEmpty(newUlincConfigData.ulinc_username)) {
      setNewUlincConfigData({
        ...newUlincConfigData,
        inputErrorMsgUlincEmail: "Missing field required.",
      });

      let element = document.getElementById("handleNewUlincConfigClickLoader");
      element.classList.add("d-none");

      return;
    }

    if (!validateNotEmpty(newUlincConfigData.ulinc_password)) {
      setNewUlincConfigData({
        ...newUlincConfigData,
        inputErrorMsgUlincPassword: "Missing field required.",
      });

      let element = document.getElementById("handleNewUlincConfigClickLoader");
      element.classList.add("d-none");

      return;
    }

    if (!validateNotEmpty(newUlincConfigData.ulinc_li_email)) {
      setNewUlincConfigData({
        ...newUlincConfigData,
        inputErrorMsgLiEmail: "Missing field required.",
      });

      let element = document.getElementById("handleNewUlincConfigClickLoader");
      element.classList.add("d-none");

      return;
    }

    if (!validateEmail(newUlincConfigData.ulinc_username)) {
      setNewUlincConfigData({
        ...newUlincConfigData,
        inputErrorMsgUlincEmail: "Please enter a valid email.",
      });

      let element = document.getElementById("handleNewUlincConfigClickLoader");
      element.classList.add("d-none");

      return;
    }

    if (!validateEmail(newUlincConfigData.ulinc_li_email)) {
      setNewUlincConfigData({
        ...newUlincConfigData,
        inputErrorMsgLiEmail: "Please enter a valid email.",
      });

      let element = document.getElementById("handleNewUlincConfigClickLoader");
      element.classList.add("d-none");
      
      return;
    }

    let body = {
      "ulinc_username": newUlincConfigData.ulinc_username,
      "ulinc_password": newUlincConfigData.ulinc_password,
      "ulinc_li_email": newUlincConfigData.ulinc_li_email
    }
    Api.createUlincConfig(body, handleNewUlincConfigClickSuccess, handleNewUlincConfigClickFailure)
  }

  const handleNewUlincConfigClickSuccess = (response) => {
    setNewUlincConfigData({
      ...newUlincConfigData,
      newUlincConfigId: response.data.ulinc_config_id,
    });

    let element = document.getElementById("handleNewUlincConfigClickLoader");
    element.classList.add("d-none");

    handleClose();

    props.refreshAccountPageDataFunction();

    console.log("this is my new ulinc response: ", response);
  } 

  const handleNewUlincConfigClickFailure = (response) => {
    if (response.data.message === "Ulinc account is not business") {
      setNewUlincConfigData({
        ...newUlincConfigData,
        inputErrorMsgUlincEmail: response.data.message
      });
    }

    if (response.data.message === "Invalid Ulinc credentials") {
      setNewUlincConfigData({
        ...newUlincConfigData,
        inputErrorMsgUlincPassword: response.data.message,
        inputErrorMsgUlincEmail: response.data.message
      });
    }

    if (response.data.message === "Incorrect Ulinc LI email") {
      setNewUlincConfigData({
        ...newUlincConfigData,
        inputErrorMsgLiEmail: "Incorrect Ulinc LinkedIn email",
      });
    }

    let element = document.getElementById("handleNewUlincConfigClickLoader");
    element.classList.add("d-none");
  } 

  function validateNotEmpty(string) {
    if (string.length > 0) {
      return true;
    }
    return false;
  }

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

   const handleNewUlincConfigConfirmClick = () => {
    setConfirmModalText({
      ...confirmModalText,
      showForm: true
    })
  }

  return (
    <div>
      <Card className={classes.root + " m-auto tableBoxShadow"}>
        <CardHeader 
          className={classes.cardHeader} 
          classes={{
            title: classes.cardTitle,
          }}
          boxshadow={3}
          title={
            <Typography className={classes.cardHeaderTitle + " white"} >
              <div className="position-relative">
                <LinkedInIcon />
                <span className={classes.titleSpan}> Accounts</span>
              </div>
            </Typography>
          }
        />
        <CardContent>
          {/*<Typography variant="body2" component="p">
            There are no accounts yet.
          </Typography>*/}

          {/* Put a list here if there is data - pass data to the accountListItem component and build out list items useing .map() */}
          {
            props.accountsData && props.accountsData.length ?
            <AccountList accountsData={props.accountsData} />
            :
            <Typography variant="body2" component="p">
              You have no connected accounts.
            </Typography>

          }
        </CardContent>
        <Divider light />
        <CardActions className="justify-content-center">
          <Button className={classes.actionButton} size="small" onClick={handleOpen}>Add Ulinc Account</Button>
        </CardActions>
      </Card>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper + " position-relative"}>
            <CloseIcon className={classes.closeIcon} onClick={handleClose} />
            
            {
              confirmModalText.showForm ?
                <Card variant="outlined">
                  <CardHeader className="text-center" titleTypographyProps={{variant:'h5'}} title="Add Ulinc Account" />
                  <CardContent className="d-flex flex-column align-items-center">
                    <TextField 
                      className={classes.inputFieldWrapper} 
                      name="ulinc_username" 
                      value={newUlincConfigData.ulinc_username} 
                      onChange={handleTextChange}
                      label="Ulinc Login Email*" 
                      type="text" 
                      variant="outlined" 
                      size="small" 
                      error={newUlincConfigData.inputErrorMsgUlincEmail}
                      helperText={newUlincConfigData.inputErrorMsgUlincEmail}
                      InputProps={{
                        classes: {
                          input: classes.inputFields,
                        },
                      }}
                    /> 
                    <TextField
                      className={classes.inputFieldWrapper}
                      name="ulinc_password"
                      value={newUlincConfigData.ulinc_password}
                      onChange={handleTextChange}
                      id="outlined-password-input"
                      label="Ulinc Login Password*"
                      type="password"
                      autoComplete="current-password"
                      variant="outlined"
                      size="small"
                      error={newUlincConfigData.inputErrorMsgUlincPassword}
                      helperText={newUlincConfigData.inputErrorMsgUlincPassword}
                      InputProps={{
                        classes: {
                          input: classes.inputFields,
                        },
                      }}
                    />
                    <TextField 
                      className={classes.inputFieldWrapper} 
                      name="ulinc_li_email" 
                      value={newUlincConfigData.ulinc_li_email} 
                      onChange={handleTextChange}
                      label="Connected LinkedIn Account Email*" 
                      type="text" 
                      variant="outlined" 
                      size="small" 
                      error={newUlincConfigData.inputErrorMsgLiEmail}
                      helperText={newUlincConfigData.inputErrorMsgLiEmail}
                      InputProps={{
                        classes: {
                          input: classes.inputFields,
                        },
                      }}
                    />
                  </CardContent>
                  <CardActions className={classes.buttonWrapper}>
                    <div className="d-flex align-items-center w-100 justify-content-center">
                        <Button size="small" variant="contained" className={classes.buttonClasses} onClick={handleNewUlincConfigClick}>Connect Account</Button>
                        <div className={classes.spinnerStyles + " spinner-border d-none ml-3"} id="handleNewUlincConfigClickLoader" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    
                  </CardActions>

                  <span className={classes.requiredFields}>*Required Fields</span>
                </Card>
              :
                <Card variant="outlined">
                  <CardHeader className="text-center" titleTypographyProps={{variant:'h5'}} title={confirmModalText.modalTitle} />
                  <CardContent className="d-flex flex-column align-items-center">
                    <Typography variant="body2" className="text-center">
                      {confirmModalText.modalText(props.billingInfo.current_subscription_price.current_subscription_price_name, props.billingInfo.current_subscription_price.current_subscription_price)}
                    </Typography>
                    <Typography variant="body2" className="text-center mt-2">
                      {confirmModalText.secondParagraphText}
                    </Typography>
                  </CardContent>
                  <CardActions className={classes.buttonWrapper}>
                    <div className="d-flex align-items-center w-100 justify-content-center">
                        <Button size="small" variant="contained" className={classes.buttonClasses} onClick={handleNewUlincConfigConfirmClick}>Confirm</Button>
                        <div className={classes.spinnerStyles + " spinner-border d-none ml-3"} id="handleNewUlincConfigConfirmClickLoader" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    
                  </CardActions>
                </Card>

            }
            
          </div>
        </Fade>
      </Modal>
    </div>
  );
}



