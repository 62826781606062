import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField, FormControl, InputLabel, Select, MenuItem, Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import BalloonEditor from 'ckeditor5-custom-build/build/ckeditor';
import * as Api from "../../api.js";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    divWrapperRight: {
        minWidth: 650,
        maxWidth: 650,
        marginLeft: "auto"
    },
    divWrapperLeft: {
        minWidth: 650,
        maxWidth: 650,
        marginRight: "auto" 
    },
    textFieldStyles: {
        background: "#FFF",
        borderRadius: 4,
        maxWidth: 90,
        "& .MuiOutlinedInput-root": {
            "& fieldset": { 
                border: "2px solid #74c69d"
            },
            '&:hover fieldset': {
                borderColor: '#60be8f',
            },
            "&.Mui-focused fieldset": {
                borderColor: "#74c69d",
                outline: "unset"
            }
        }
    },
    textAreaStyles: {
        minWidth: 400,
        maxWidth: 650,
        marginTop: "20px !important",
        borderRadius: 7,
        padding: "5px 8px",
        "&:focus": {
            outline: "unset",
            border: "unset"
        }
    },
    emailSubjectStyles: {
        minWidth: 500
    },
    emailTestRecipientStyles: {
        minWidth: 275
    },
    richTextEditor: {
        background: "#FFF"
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
          borderColor: `yellow !important`,
        }
    },
    selectInputField: {
        boxshadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
        borderRadius: 5,
        display: 'block',
        maxWidth: 320,
        minWidth: 275,
        height: 40,
        marginTop: '-10px'
    },
    accordion: {
        background: 'transparent',
        boxShadow: 'unset'
    },
    accordionDetails: {
        marginTop: '-10px'
    },
    accordionTextWrapper: {
        padding: '0 0 0 10px'
    },
    accordionText: {
        color: '#585563',
        fontWeight: 'bold'
    },
    fontOverride: {
        fontFamily: 'Arial !important'
    },
    spinnerStyles: {
        color: "#74c69d",
    }
}));

export default function EmailMessageDiv(props) {
    const classes = useStyles();

    const [bodyData, setBodyData] = React.useState(
        props.messageData.janium_campaign_step_body
    )
    const [delayData, setDelayData] = React.useState(
        props.messageData.janium_campaign_step_delay
    )
    const [subjectData, setSubjectData] = React.useState(
        props.messageData.janium_campaign_step_subject
    )
    const [emails, setEmails] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [ulincContactInfo, setUlincContactInfo] = React.useState({
        janiumCampaignId: ""
    })

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };
    const [testEmailData, setTestEmailData] = React.useState({
        "email_config_id": "",
        "recipient_email": ""
    })

    const[disabledInputs, setDisabledInputs] = React.useState({
        subjectLine: false,
        delay: false,
        ckeditor: false
    })

    const handleChange = (event) => {
        props.setTestMessageIndexMethod(props.messageData.janium_campaign_message_index);

        setTestEmailData({
            ...testEmailData,
            [event.target.name]: event.target.value
        })
    }

    React.useEffect(() => {
        var url_string = window.location.href; 
        var url = new URL(url_string);
        var janiumCampaignId = url.searchParams.get("janiumCampaignId");

        const newMessageData = {
            ...props.messageData,
            "janium_campaign_step_body": bodyData,
            "janium_campaign_step_delay": parseInt(delayData),
            "janium_campaign_step_subject": subjectData
        }

        props.inputChangeHandler(newMessageData, props.indexVal)

        if (props.testData) {
            setUlincContactInfo(props.testData)
        }

        logicToDisableInputs();
        setEmails(props.emailList);

    }, [bodyData, delayData, subjectData, props.emailConfigId, props.isReplyInThread, props.emailList])

    const handleSaveAndTestMessages = () => {
        let element = document.getElementById("saveAndTestButtonLoading" + props.indexVal);
        element.classList.remove("d-none");
        
        props.saveMethod(handleSendTestMessage);
    }

    const handleSendTestMessage = (campaignStepId) => {
        const testMessageData = {
            "janium_campaign_id": ulincContactInfo.janiumCampaignId,
            "janium_campaign_step_id": campaignStepId,
            "email_config_id": testEmailData.email_config_id,
            "recipient_email": testEmailData.recipient_email
        }

        Api.testEmailMessaging(testMessageData, testEmailMessagingSuccess, testEmailMessagingFailure)
    }

    const testEmailMessagingSuccess = (response) => {
        console.log("email message test success: ", response);

        let element = document.getElementById("saveAndTestButtonLoading" + props.indexVal);
        element.classList.add("d-none");
    }

    const testEmailMessagingFailure = (response) => {
        console.log("email message test failure: ", response);

        let element = document.getElementById("saveAndTestButtonLoading" + props.indexVal);
        element.classList.add("d-none");
    }

    const logicToDisableInputs = () => {
        let disableSubjectLine = false;
        let disableDelay = false;
        let disbleCkeditor = false;

        if (!props.emailConfigId) {
            disableSubjectLine = true;
            disableDelay = true;
            disbleCkeditor = true;
        } else {
            disableSubjectLine = props.isReplyInThread ? (((props.isEnriched && props.indexVal === 0) || (!props.isEnriched && props.indexVal === 1)) ? false : true) : false;
        }

        setDisabledInputs({
            subjectLine: disableSubjectLine,
            delay: disableDelay,
            ckeditor: disbleCkeditor
        })


    }

    return (
        <div className={props.leftSide ? classes.divWrapperLeft : classes.divWrapperRight}>
            <Typography variant="h6" gutterBottom>
            {"Step " + (props.indexVal + 2) + ": " + ((props.indexVal)  ? "Email Follow-up #" + (props.isEnriched ? (props.indexVal + 1) : ((props.indexVal + 1)/2)) : "Email Follow-up #1")}
            </Typography>
            <div className="d-flex justify-content-between mb-2 emailMessagingFontOverride">
                <TextField 
                    className={classes.textFieldStyles + " " + classes.emailSubjectStyles} 
                    name="subject"
                    id="outlined-basic" 
                    disableUnderline={true} 
                    disabled={disabledInputs.subjectLine}
                    label={((props.indexVal)  ? "Email Follow-up #" + (props.isEnriched ? (props.indexVal + 1) : ((props.indexVal + 1)/2)) : "Email Follow-up #1") + " Subject"}
                    variant="outlined" 
                    size="small" 
                    onChange={(e) => {
                        setSubjectData(e.target.value);
                    }} 
                    value={subjectData ?? ""} 
                    InputProps={{
                        style: {fontFamily: "Arial !important"},
                    }}
                />
                <TextField 
                    className={classes.textFieldStyles} 
                    InputProps={{
                        className: classes.fontOverride
                    }}
                    name="delay"
                    id="outlined-basic" 
                    disabled={disabledInputs.delay}
                    label="EM Delay" 
                    variant="outlined" 
                    size="small"
                    onChange={(e) => {
                        setDelayData(e.target.value);
                    }}  
                    value={delayData ?? ""} 
                />
            </div>
            
            {props.emailConfigId ?
                <CKEditor
                    editor={ BalloonEditor }
                    config={{
                        // plugins: [ Font ],
                        extraAllowedContent: 'div(*)',
                        allowedContent: true,
                        placeholder: "Enter " + ((props.indexVal)  ? "Email Follow-up #" + (props.isEnriched ? (props.indexVal + 1) : ((props.indexVal + 1)/2)) : "Email Follow-up #1")  + " message text here...",
                        toolbar: ['fontFamily', 'bold', 'italic', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo'],
                        
                        fontFamily: {
                            options: [
                                // "default",
                                "Arial, sans-serif",
                                // "Ubuntu Mono, Courier New, Courier, monospace",
                            ],
                        },
                    }}
                    data={bodyData ?? ""} 
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setBodyData(data);
                    } }
                    onFocus={ ( event, editor ) => {
                        document.querySelector('.ck-body-wrapper').classList.remove('d-none');
                    } }
                    disabled={disabledInputs.ckeditor}
                    // onClick={ (event, editor) => {
                    //     const data = editor.getData();
                    // }}
                />
            :
                <Tooltip 
                    title={
                    <>
                        <p>You have not selected an “Email Sending Identity” for this Janium campaign.</p>
                        <p className="mb-0">Please select one for email steps to be included in your messaging sequence.”</p>
                    </>
                    }
                >
                    <div>
                        <CKEditor
                            editor={ BalloonEditor }
                            config={{
                                // plugins: [ Font ],
                                extraAllowedContent: 'div(*)',
                                allowedContent: true,
                                placeholder: "Enter " + ((props.indexVal)  ? "Email Follow-up #" + (props.isEnriched ? (props.indexVal + 1) : ((props.indexVal + 1)/2)) : "Email Follow-up #1")  + " message text here...",
                                toolbar: ['fontFamily', 'bold', 'italic', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo'],
                                
                                fontFamily: {
                                    options: [
                                        // "default",
                                        "Arial, sans-serif",
                                        // "Ubuntu Mono, Courier New, Courier, monospace",
                                    ],
                                },
                            }}
                            data={bodyData ?? ""} 
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setBodyData(data);
                            } }
                            onFocus={ ( event, editor ) => {
                                document.querySelector('.ck-body-wrapper').classList.remove('d-none');
                            } }
                            disabled={disabledInputs.ckeditor}
                            // onClick={ (event, editor) => {
                            //     const data = editor.getData();
                            // }}
                        />
                    </div>
                </Tooltip>
            
            }
            
            <Accordion className={classes.accordion + " accordionStyles emailMessagingFontOverride"}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon className="pt-0"/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordionTextWrapper + " mb-0"}
                >
                    <Typography variant="body2" className={classes.accordionText + " mb-0"}>Test Step</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails + " d-flex flex-column"}>
                    <div className="d-flex justify-content-between mb-3">
                        <FormControl className={classes.selectInputField}>
                            <InputLabel id="janiumCampaignEmailConfig">Janium Campaign Email Config</InputLabel>
                            <Select
                                labelId="janiumCampaignEmailConfig"
                                name="email_config_id"
                                value={testEmailData.email_config_id}
                                onChange={handleChange}
                                className={"w-100"}
                            >
                                {
                                    emails.map((emailData, i) => {
                                        return (
                                            <MenuItem key={i} value={emailData.email_config_id}>{emailData.from_full_name + " <" + emailData.from_address + ">"}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                        <TextField 
                            className={classes.textFieldStyles + " " + classes.emailTestRecipientStyles} 
                            InputProps={{
                                className: classes.fontOverride
                            }}
                            name="recipient_email"
                            id="outlined-basic" 
                            label="Test Recipient Email" 
                            variant="outlined" 
                            size="small"
                            onChange={handleChange}
                            value={testEmailData.recipient_email} 
                        />
                    </div>
                    <div className="position-relative d-flex">
                        <Button onClick={handleSaveAndTestMessages} variant="contained" color="primary" disabled={(testEmailData.email_config_id && testEmailData.recipient_email) ? false : true} >
                            Save & Test Message Step
                        </Button>
                        <div class={classes.spinnerStyles + " spinner-border ml-2 d-none"} id={"saveAndTestButtonLoading" + props.indexVal} role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}