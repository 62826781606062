import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardActions, CardContent, Button, TextField }  from '@material-ui/core';
import SelectTimezoneMaterialUi from "select-timezone-material-ui";
import {BrowserRouter as Router, useHistory } from "react-router-dom";
import * as Api from "../api.js";

const useStyles = makeStyles({
  root: {
    minWidth: 325,
    maxWidth: 375,
    paddingBottom: 20,
    boxshadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)'
  },
  wrapper: {
    minHeight: "100vh"
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  anchor: {
    color: '#007bff!important',
    cursor: 'pointer'
  },
  inputFields: {
  	minWidth: 275,
  	marginBottom: 15,
  	boxshadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  	borderRadius: 5
  },
  selectInputFields: {
    minWidth: "unset",
    width: 265
  },
  buttonWrapper: {
  	paddingLeft: 30,
  	paddingRight: 30
  },
  buttonClasses: {
  	background: '#74C69D',
  	color: '#FFF',
  	minWidth: 125,
  	width: '100%',
  	transition: '0.1s linear',
  	boxshadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  	'&:hover': {
      backgroundColor: '#74C69D',
      transform: 'scale(1.05)'
    }
  }
});

export default function Signup(props) {
  const classes = useStyles();

  const [signUpData, setSignUpData] = React.useState({
    username: "",
    password: "",
    signUpPasswordConfirm: "",
    first_name: "",
    last_name: "",
    title: "",
    company: "",
    emailErrorMessage: "",
    passwordErrorMessage: "",
    time_zone_code: "US/Mountain",
    generalErrorMessage: ""
  });

  React.useEffect(() => {
}, [signUpData])

  const handleSignUpTextChange = (event) => {
    setSignUpData({
        ...signUpData, 
      [event.target.name]: event.target.value,
      emailErrorMessage: "",
      passwordErrorMessage: ""
    });
  }

  const history = useHistory();
 

  const handleSignUp = () => {
    if (!validateEmail(signUpData.username)) {
      setSignUpData({
        ...signUpData,
        emailErrorMessage: "please enter a valid email",
      });

      return;
    }

    if (signUpData.password !== signUpData.signUpPasswordConfirm) {
      setSignUpData({
        ...signUpData,
        passwordErrorMessage: "passwords do not match",
        signUpPasswordConfirm: "",
        first_name: ""
      });

      return;
    }

    var url_string = window.location.href; 
    var url = new URL(url_string);
    var referralKey = url.searchParams.get("key");

    let params = {
      username: signUpData.username,
      password: signUpData.password,
      first_name: signUpData.first_name,
      last_name: signUpData.last_name,
      title: signUpData.title,
      company: signUpData.company,
      time_zone_code: signUpData.time_zone_code,
      key: referralKey
    }

    Api.signUp(params, signUpSuccess, signUpFailed);
  }

  const signUpSuccess = (response) => {
    let path = `/`; 

    alert('Sign up successful');
    
    history.push(path);
  }

  const signUpFailed = (response) => {
    if (response.data.message === "Invalid Key") {
        setSignUpData({
            ...signUpData,
            generalErrorMessage: response.data.message,
        });
    } else if (response.data.message === "Missing Key") {
        setSignUpData({
            ...signUpData,
            generalErrorMessage: response.data.message,
        });
    }

    if (response.data.message === "Username already exists") {
        setSignUpData({
            ...signUpData,
            emailErrorMessage: "This username already exists",
        });
    }
  }

  const enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) { 
        handleSignUp();
    } 
  }

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function onChangeTimezone(timezoneName, timezoneOffset) {
    setSignUpData({
        ...signUpData, 
      time_zone_code: timezoneName
    });
  };

  return (
  	<div className={classes.wrapper + " d-flex justify-content-center align-items-center h-100 py-5 my-5"}>
        <Card className={classes.root + " tableBoxShadow"} variant="outlined">
          <CardHeader className="text-center" titleTypographyProps={{variant:'h5'}} title="Sign Up for Janium" />
          <CardContent className="d-flex flex-column align-items-center">
            <div className="red" id="noReferralKeyId">{signUpData.generalErrorMessage}</div>
            <TextField 
              className={classes.inputFields} 
              onChange={handleSignUpTextChange} 
              label="First Name" 
              type="text" 
              variant="outlined"
              name="first_name" 
              value={signUpData.first_name} 
              inputProps={{
                autocomplete: 'new-firstName',
                form: {
                  autocomplete: 'off',
                },
              }}
            />
            <TextField className={classes.inputFields} onChange={handleSignUpTextChange} label="Last Name" type="text" variant="outlined" name="last_name" value={signUpData.last_name} autocomplete="off" />
            <TextField className={classes.inputFields} onChange={handleSignUpTextChange} label="Title" type="text" variant="outlined" name="title" value={signUpData.title} autocomplete="off" />
            <TextField className={classes.inputFields} onChange={handleSignUpTextChange} label="Company" type="text" variant="outlined" name="company" value={signUpData.company} autocomplete="off" />
            {/* <TextField className={classes.inputFields} onChange={handleTextChange} label="accountAdminEmail" type="text" variant="outlined" name="accountAdminEmail" value={signUpData.accountAdminEmail} /> */}
            <TextField className={classes.inputFields} 
              onChange={handleSignUpTextChange} 
              label="Username (E-mail)" 
              type="text" 
              variant="outlined" 
              name="username" 
              value={signUpData.username} 
              error={signUpData.emailErrorMessage}
              helperText={signUpData.emailErrorMessage}
              autocomplete="off"
            />
            <TextField
              className={classes.inputFields}
              id="outlined-password-input"
              label="Password"
              type="password"
              autoComplete="current-password"
              variant="outlined"
              name="password"
              onChange={handleSignUpTextChange}
              onKeyUp={enterPressed}
              value={signUpData.password}
              error={signUpData.passwordErrorMessage}
              helperText={signUpData.passwordErrorMessage}
              autocomplete="off"
            />
            <TextField
              className={classes.inputFields}
              id="outlined-password-input"
              label="Re-confirm Password"
              type="password"
              variant="outlined"
              name="signUpPasswordConfirm"
              onChange={handleSignUpTextChange}
              value={signUpData.signUpPasswordConfirm}
              autocomplete="off"
            />
            <SelectTimezoneMaterialUi
              label="Timezone"
              name={"time_zone_code"}
              value={signUpData.time_zone_code}
              defaultTimezoneName="US/Mountain"
              helperText="Please select a timezone from the list"
              onChange={onChangeTimezone}
              className={classes.inputFields + " " + classes.selectInputFields}
            />

          </CardContent>
          <CardActions className={classes.buttonWrapper + " d-flex flex-column"}>
            <Button onClick={handleSignUp} variant="contained" className={classes.buttonClasses + " mb-3"}>SIGN UP</Button>
          </CardActions>
        </Card>
        
  		
    </div>
  );
}