import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { AccountsCard, CommunicationStatsTable } from "../components";
import {BrowserRouter as Router, useHistory } from "react-router-dom";
import * as Api from "../api.js";

const useStyles = makeStyles({
  root: {
    
  }, 
  tablesWrapper: {
    maxWidth: 1400
  },
  textBox: {
    margin: 'auto',
    padding: 18,
    background: '#e6e6e6',
    maxWidth: 620,
    textAlign: 'center',
    border: '1px solid #d8d8d8'
  },
  textBoxHyperlink: {
    color: '#0A369D !important',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline !important'
    }
  }
});

export default function Accounts(props) {
  const classes = useStyles();
  const history = useHistory();

  const [accountPage, setAccountPageData] = React.useState({
    accountPageData: {}
  })
  const [boxText, setBoxText] = React.useState({
    shownTextFirst: "",
    shownTextSecond: "",
    rerouteFunction: function() {
    }
  })
  const [showAccountPage, setShowAccountPage] = React.useState(true)
  const [refreshAccountPageData, setRefreshAccountPageData] = React.useState()

  const routeChange = (path) => { 
    history.push(path);
  }


  const [billingInfo, setBillingInfo] = React.useState({
    "is_admin_paid": false,
    "has_payment_method": false,
    "current_subscription_price": {
      "current_subscription_price_id": "",
      "current_subscription_price_name": "",
      "current_subscription_price": ""
    },
    "seats": 1,
    "payment_method": {
        "payment_method_last_4": "",
        "payment_method_holder_name": "",
        "payment_method_issuer": "",
        "payment_method_expiration": ""
    },
    "subscription_prices": [
      {
        "subscription_price_name": "",
        "subscription_price_id": "",
        "subscription_price": ""
      },
      {
        "subscription_price_name": "",
        "subscription_price_id": "",
        "subscription_price": ""
      }
    ]
  })

  React.useEffect(() => {
    props.siteLoader(true);
    Api.getAccountBillingInfo(getAccountBillingInfoSuccess, getAccountBillingInfoFailure)
  }, [])

  const getAccountBillingInfoSuccess = (response) => {
    setBillingInfo(response.data);

    // set up variable that determines state of page
    if (response.data.is_admin_paid) {
      setShowAccountPage(true);

      return;
    } else if (!response.data.has_payment_method) {
      setShowAccountPage(false);

      setBoxText({
        ...boxText,
        shownTextFirst: "You have not added a payment method, please click ",
        shownTextSecond: " to add one.",
        rerouteFunction: function() {
          Api.getAddPaymentUrl(getAddPaymentUrlSuccess, getAddPaymentUrlFailure)
        }
      })

      return;
    } else if (!response.data.current_subscription_price.current_subscription_price_id) {
      setShowAccountPage(false);

      setBoxText({
        ...boxText,
        shownTextFirst: "You have not selected a plan yet, click ",
        shownTextSecond: " to select one.",
        rerouteFunction: function() {
          routeChange('/subscription')
        }
      })

      return;
    }
  }

  const getAccountBillingInfoFailure = (response) => {
    console.log("setting page email req fail: ", response);
  }



  React.useEffect(() => {
    Api.getAccountPageData(accountPageDataSuccess, accountPageDataFailure)
  }, [refreshAccountPageData])

  const accountPageDataSuccess = (response) => {
    setAccountPageData({...accountPage, accountPageData: response.data});

    props.siteLoader(false);
  }

  const accountPageDataFailure = () => {
    console.log("could not pull data");
  }

  const refreshAccountPageDataFunction = () => {
    setRefreshAccountPageData(true)
  }

  const getAddPaymentUrlSuccess = (response) => {
    window.open(response.data.stripe_add_payment_url, '_blank');
  }

  const getAddPaymentUrlFailure = () => {
    console.log("could not pull data");
  }

  return (
  	<div className={classes.root + " h-100"}>
      <br />
      {
        showAccountPage ?
          <div>
            <AccountsCard accountsData={accountPage.accountPageData} refreshAccountPageDataFunction={refreshAccountPageDataFunction} billingInfo={billingInfo} /> 
            <br />
            <div className={classes.tablesWrapper + " d-flex justify-content-around flex-column align-items-center px-5 mx-auto mt-3"}>
              {
                accountPage.accountPageData.length ?
                  accountPage.accountPageData.map((accountData, i) => (
                    <div className="mb-5">
                      <CommunicationStatsTable accountsData={accountData} />
                    </div>
                  ))
                :
                <div></div>
              }
            </div>
          </div>
        :
          <div className={classes.textBox + " tableBoxShadow mt-4"}>
            {boxText.shownTextFirst} <button className={classes.textBoxHyperlink + " anchor"} onClick={boxText.rerouteFunction}>here</button> {boxText.shownTextSecond}
          </div>
      }
		  
    </div>
  );
}

