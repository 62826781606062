import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Modal, Backdrop, Fade, Card, CardHeader, CardContent, CardActions }  from '@material-ui/core';
import { EmailMessageDiv } from "..";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import greenCheck from '../../assets/greenCheck.png';
import redX from '../../assets/redX.png';
import * as Api from "../../api.js";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '5px',
        boxshadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 550
      },
    cardContent: {
        padding: '16px 2px 16px 0'
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    messagesContainer: {
        background: "linear-gradient(#f3f3f6, #FFF)",
        marginTop: 20,
        borderRadius: 7,
        padding: "10px 8px",
        display: "flex",
        flexDirection: "column",
        maxWidth: 1250,
        margin: "0 auto"
    },
    emailKeyWrapper: {
        background: '#FFF',
        borderRadius: 5,
        position: 'relative'
    },
    buttonClass: {
        width: 300,
        margin: "0 auto",
        background: "#74c69d",
        color: "#FFF",
        '&:focus': {
            outline: "unset"
        },
        '&:hover': {
            background: "#63bf91",
            boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)"
        }
    },
    modalButtonClasses: {
        background: '#74C69D',
        color: '#FFF',
        minWidth: 125,
        width: 'fit-content',
        transition: '0.1s linear',
        boxshadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
        fontSize: 14,
        '&:hover': {
          backgroundColor: '#74C69D',
          transform: 'scale(1.05)'
        }
    },
    spinnerStyles: {
        color: "#74c69d",
        position: "absolute",
        left: "103%"
    },
    lineHeight: {
        lineHeight: '1.2',
        fontSize: 12,
        marginBottom: 5
    },
    marginFix: {
        marginTop: '-35px'
    },
    infoIconWrapper: {
        position: 'absolute',
        top: '-15px',
        right: 0
    },
    infoIcon: {
        width: 22
    },
    emailSafeIcon: {
        marginLeft: 3,
        marginRight: 5,
        height: 25,
        verticalAlign: 'bottom'
    },
    closeIcon: {
        position: 'absolute',
        right: 2,
        top: 2,
        color: '#81868f',
        cursor: 'pointer'
    },
    inlineButtons: {
        background: 'transparent',
        marginLeft: 8,
        color: '#74C69D',
        height: 25,
        width: 'fit-content',
        transition:'1s',
        transitionTimingFunction: 'easy-in',
        '&:focus': {
            outline: 'unset'
        }
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    body2: {
        fontSize: '0.875rem'
    }
}));

export default function CampaignMessagingDataEnrich(props) {
    const classes = useStyles();
    const [items, setMessageData] = React.useState([]);
    const [emails, setEmails] = React.useState([]);
    const [campaignContactsData, setCampaignContactsData] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const [firstNameFade, setFirstNameFade] = React.useState(false);
    const [forwardingKeyFade, setForwardingKeyFade] = React.useState(false);
    const [modalText, setModalText] = React.useState({
        title: "Warning!",
        bodyOne: "",
        bodyTwo: "",
        buttonText: "",
        handleModalButtonClick: function() {
        }
    })
    const [testDataInfo, setTestDataInfo] = React.useState({
        janiumCampaignId: ""
    })

    const [testMessageIndex, setTestMessageIndex] = React.useState()

    const updateTestMessageIndex = (index) => {
        setTestMessageIndex(index)
    }

    const handleOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        props.siteLoader(true);

        var url_string = window.location.href; 
        var url = new URL(url_string);
        var janiumCampaignId = url.searchParams.get("janiumCampaignId");

        let params = {
            "janium_campaign_id": janiumCampaignId,
            "is_data_enrichment": "true"
        }
        Api.getCampaignMessages(params, getCampaignMessagesSuccess, getCampaignMessagesFailure)
    }, [])

    React.useEffect(() => {
        // console.log(items);
    }, [items])

    React.useEffect(() => {
        Api.getCampaignEmails(getCampaignEmailsSuccess, getCampaignEmailsFailure)
    }, [])

    React.useEffect(() => {
        var url_string = window.location.href; 
        var url = new URL(url_string);
        var janiumCampaignId = url.searchParams.get("janiumCampaignId");

        let params = {
            janiumCampaignId: janiumCampaignId
        }

        Api.getCampaignContacts(params, getCampaignContactsDataSuccess, getCampaignContactsDataFailure)
    }, [])

    const getCampaignContactsDataSuccess = (response) => {
        setCampaignContactsData(response.data);

        setTestDataInfo({
            janiumCampaignId: response.data.janium_campaign_id
        })

        props.siteLoader(false);
    }

    const getCampaignContactsDataFailure = () => {
        console.log('campaign contacts call failed');
    }

    const getCampaignEmailsSuccess = (response) => {
        setEmails(response.data);
    }

    const getCampaignEmailsFailure = (response) => {
        console.log("setting page email req fail: ", response);
    }

    const getCampaignMessagesSuccess = (response) => {
        setMessageData(response.data);
    }

    const getCampaignMessagesFailure = (data) => {
        console.log("failure to get enriched messaging data: ", data);
    }

    const recievedInputChange = (updatedData, index) => {
        setMessageData([
            ...items.slice(0, index),
            updatedData,
            ...items.slice(index + 1)
        ])
    }

    const updateEnrichedMessages = (success) => {
        let element = document.getElementById("buttonLoading");
        element.classList.remove("d-none");

        var url_string = window.location.href; 
        var url = new URL(url_string);
        var janiumCampaignId = url.searchParams.get("janiumCampaignId");

        let params = {
            "janium_campaign_id": janiumCampaignId,
            "is_data_enrichment": "true"
        }
        Api.updateMessages(items, params, function(e) {
            updateEnrichedMessagesSuccess(e, success)
        }, updateEnrichedMessagesFailure)
    }

    const updateEnrichedMessagesSuccess = (e, runTestMessage) => {
        setMessageData(e.data.janium_campaign_steps);

        let element = document.getElementById("buttonLoading");
        element.classList.add("d-none");

        setCampaignContactsData({
            ...campaignContactsData,
            is_email_step_safe: e.data.is_email_step_safe
        })

        if (!e.data.is_email_step_safe) {
            if (campaignContactsData.is_reply_in_email_thread) {
                setModalText({
                    ...modalText,
                    bodyOne: "The message body of Email Follow-up #1 currently doesn’t contain the forwarding key. Emails for your Janium campaign won’t be sent until this has been fixed to ensure the integrity of your campaign.",
                    bodyTwo: "Please add " + campaignContactsData.forwarding_key + " to the message body of Email Follow-up #1 to fix this.",
                    buttonText: "Copy Forwarding Key",
                    handleModalButtonClick: function() {
                        navigator.clipboard.writeText(campaignContactsData.forwarding_key)
                    }
                })
            } else {
                setModalText({
                    ...modalText,
                    bodyOne: "The message body of one or more of your email steps currently doesn’t contain the forwarding key. Emails for your Janium campaign won’t be sent until this has been fixed to ensure the integrity of your campaign.",
                    bodyTwo: "Please make sure " + campaignContactsData.forwarding_key + " is included in the message body of each email step to fix this.",
                    buttonText: "Copy Forwarding Key",
                    handleModalButtonClick: function() {
                        navigator.clipboard.writeText(campaignContactsData.forwarding_key)
                    }
                })
            }
            
            handleOpen()
        }
        let messageToTest = e.data.janium_campaign_steps.filter(obj => {
            return obj.janium_campaign_message_index === testMessageIndex
        })

        if (typeof runTestMessage === "function") {
            runTestMessage(messageToTest[0].janium_campaign_step_id);
        }
        

        console.log("successful message update: ", e);
    }

    const updateEnrichedMessagesFailure = (response, msg) => {
        console.log("failure to message update: ", response);

        if (response.data.message !== "Unknown janium_campaign_step_id value" || response.data.message !== "Missing Authorization Header" || response.data.message !== "Request failed with status code 400") {
            alert(response.data.message)
        }

        let element = document.getElementById("buttonLoading");
        element.classList.add("d-none");
    }

    const copyFirstNameToClipboard = () => {
        document.getElementById("firstNameCopy").style.display = 'none';
        setFirstNameFade(true);
        navigator.clipboard.writeText("{FirstName}");

        setTimeout(() => {
            setFirstNameFade(false);
        }, 750)

        setTimeout(() => {
            document.getElementById("firstNameCopy").style.display = 'inline';
        }, 1250)
        
    }

    const copyForwardingKeyToClipboard = () => {
        document.getElementById("forwardingKeyCopyIcon").style.display = 'none';
        setForwardingKeyFade(true);
        navigator.clipboard.writeText(campaignContactsData.forwarding_key);

        setTimeout(() => {
            setForwardingKeyFade(false);
        }, 750)

        setTimeout(() => {
            document.getElementById("forwardingKeyCopyIcon").style.display = 'inline';
        }, 1250)
    }

    return (
        <div>
            <div className={classes.messagesContainer + " tableBoxShadow"}>
                <Typography variant="h6" gutterBottom>
                    Step 1: LinkedIn Connection Request (Initiated by Ulinc) - Day 0
                </Typography>
                <div className="d-flex w-100">
                    <Typography variant="body2" gutterBottom className="col-7">
                        Janium is engineered to trigger off of actions that are inititated in Ulinc. Create a 1-step connector
                        campaign in Ulinc and then associate this Janium campaign to that Ulinc campaign on the contacts tab
                        (to the left). Once associated, Janium will take care of the rest of the steps after the connection request
                        and will produce verified contact data for a portion of those contacts.
                    </Typography>
                    <div gutterBottom className={classes.marginFix + " col-5"}>
                        <div className={classes.emailKeyWrapper + " p-2 tableBoxShadow"}>
                            <Typography variant="body1" gutterBottom>
                                <span className="font-weight-bold">Messaging Tags: </span>
                            </Typography>
                            <Typography variant="body2" gutterBottom className="d-flex">
                                Contact First Name: &nbsp;
                                <div onClick={copyFirstNameToClipboard} className={classes.cursorPointer} > 
                                     &#123;FirstName&#125;
                                    <FileCopyOutlinedIcon  id="firstNameCopy" className={classes.inlineButtons}  />
                                </div>

                                <Fade in={firstNameFade} timeout={500} >
                                    <div>
                                        <CheckCircleOutlineOutlinedIcon className={classes.inlineButtons}  />
                                        Copied!
                                    </div>
                                </Fade>
                            </Typography>
                            {
                                campaignContactsData.email_config_id ?
                                <div>
                                    <hr />
                                    <div className="position-relative">
                                        <Typography variant="body1" gutterBottom className="d-flex">
                                            <span className="font-weight-bold">Email Forwarding Key: &nbsp; </span>
                                            <div onClick={copyForwardingKeyToClipboard} className={classes.cursorPointer}> 
                                                {campaignContactsData.forwarding_key}
                                                <FileCopyOutlinedIcon id="forwardingKeyCopyIcon" className={classes.inlineButtons}  />
                                            </div>

                                            <Fade in={forwardingKeyFade} timeout={500} >
                                                <div>
                                                    <CheckCircleOutlineOutlinedIcon className={classes.inlineButtons}  />
                                                    Copied!
                                                </div>
                                            </Fade>
                                        </Typography>
                                        <Typography variant="body2" className={classes.lineHeight}>
                                            *The message body of Email Follow-up #1 must contain your forwarding key.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>

                                            {
                                                campaignContactsData.is_email_step_safe ?
                                                <Typography variant="body2">
                                                    <img className={classes.emailSafeIcon} src={greenCheck} alt="Green Checkmark" />
                                                    You have properly placed your Email Forwarding Key
                                                </Typography>
                                                :
                                                <Typography variant="body2">
                                                    <img className={classes.emailSafeIcon} src={redX} alt="Red X" />
                                                    You have not properly placed your Email Forwarding Key
                                                </Typography>
                                            }
                                            
                                        </Typography>
                                        <div className={classes.infoIconWrapper}>
                                            {
                                                campaignContactsData.is_reply_in_email_thread ?
                                                <Tooltip 
                                                    title={
                                                    <>
                                                        <p>The Email Forwarding Key is used to automatically forward email campaign responses that hit your email inbox back to Janium. 
                                                        This allows Janium to handle those responses and turn off the campaign for a contact that responds via email.</p>
                                                        <p className="mb-0">In order for a Janium campaign to effectively use the email forwarding key, it must be included in the message body of Email Follow-up #1.</p>
                                                    </>
                                                    }
                                                >
                                                    <InfoOutlinedIcon className={classes.infoIcon} />
                                                </Tooltip>
                                                :
                                                <Tooltip 
                                                    title={
                                                    <>
                                                        <p>The Email Forwarding Key is used to automatically forward email campaign responses that hit your email inbox back to Janium. 
                                                        This allows Janium to handle those responses and turn off the campaign for a contact that responds via email.</p>
                                                        <p className="mb-0">In order for a Janium campaign to effectively use the email forwarding key, it must be included in the message body of each email step.</p>
                                                    </>
                                                    }
                                                >
                                                    <InfoOutlinedIcon className={classes.infoIcon} />
                                                </Tooltip>
                                            }  
                                        </div>
                                    </div>
                                </div>
                                :
                                <div>
                                    <hr />
                                    <Typography variant="body1" gutterBottom >
                                        You have not selected an “Email Sending Identity” for this Janium campaign. Please select one for email steps to be included in your messaging sequence.
                                    </Typography>
                                </div>
                            }
                            
                        </div>
                    </div>
                </div>
                
                {   
                    items.map((messagingData, i) => {
                        if (messagingData.janium_campaign_step_type_id === 4) {
                            if (i%2 === 0) {
                                return (
                                    <EmailMessageDiv 
                                        key={i} 
                                        indexVal={i} 
                                        isEnriched={true} 
                                        emailList={emails} 
                                        leftSide={true} 
                                        isReplyInThread={campaignContactsData.is_reply_in_email_thread} 
                                        emailConfigId={campaignContactsData.email_config_id}
                                        inputChangeHandler={recievedInputChange} 
                                        messageData={messagingData} 
                                        testData={testDataInfo}
                                        saveMethod={updateEnrichedMessages}
                                        setTestMessageIndexMethod={updateTestMessageIndex}
                                    />
                                )
                            } else {
                                return (
                                    <EmailMessageDiv 
                                        key={i} 
                                        indexVal={i} 
                                        isEnriched={true}
                                        emailList={emails} 
                                        isReplyInThread={campaignContactsData.is_reply_in_email_thread} 
                                        emailConfigId={campaignContactsData.email_config_id} 
                                        inputChangeHandler={recievedInputChange} 
                                        messageData={messagingData} 
                                        testData={testDataInfo}
                                        saveMethod={updateEnrichedMessages}
                                        setTestMessageIndexMethod={updateTestMessageIndex}
                                    />
                                )
                            }
                        }
                    })
                }

                <br />
                <div className="d-flex align-items-center justify-content-center">
                    <div className="position-relative">
                        <Button onClick={updateEnrichedMessages} variant="contained" color="primary" className={classes.buttonClass + " mr-3 mx-auto"}>
                            Save Campaign
                        </Button>
                        <div class={classes.spinnerStyles + " spinner-border d-none"} id="buttonLoading" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}>
                <div className={classes.modalPaper + " position-relative"}>
                    <CloseIcon className={classes.closeIcon} onClick={handleClose} />
                    <Card variant="outlined">
                    <CardHeader className="text-center" titleTypographyProps={{variant:'h5'}} title={modalText.title} />
                    <CardContent className="d-flex flex-column align-items-center">
                        <Typography variant="string" gutterBottom align="center">
                        {modalText.bodyOne} 
                        </Typography>
                        <Typography variant="string" gutterBottom align="center" >
                        {modalText.bodyTwo} 
                        </Typography>
                    </CardContent>
                    <CardActions className={classes.modalButtonWrapper}>
                        <div className="d-inline-block w-100 text-center text-nowrap">
                            <Button variant="contained" className={classes.modalButtonClasses + " mb-2"} onClick={modalText.handleModalButtonClick}>{modalText.buttonText}</Button>
                        </div>  
                    </CardActions>
                    </Card>
                </div>
                </Fade>
            </Modal>
        </div>
    );
}