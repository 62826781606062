import React from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { Typography, FormControlLabel, TextField, Button, Checkbox }  from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';
import * as Api from "../api.js";

const useStyles = makeStyles((theme) => ({
    wrapper: {
    },
    spinnerStyles: {
        color: "#74c69d" 
    },
    inputFields: {
        minWidth: 275,
        maxWidth: 275,
        marginBottom: 15,
        boxshadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
        borderRadius: 5
    },
    buttonClasses: {
        background: '#74C69D',
        color: '#FFF',
        minWidth: 125,
        maxWidth: 350,
        fontSize: 14,
        width: "fit-content",
        transition: '0.1s linear',
        boxshadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
        margin: '12px 0 12px 0',
        '&:hover': {
          backgroundColor: '#74C69D',
          transform: 'scale(1.05)'
        }
    },
    stepDescription: {
        color: 'rgba(0, 0, 0, 0.5)',
        paddingLeft: 30
    },
    fontBold: {
        fontWeight: 600
    },
    tableRoot: {
        background: '#74c69d'
    },
    tableHeaders: {
        padding: 10,
        fontSize: 14,
        color: "#FFF",
        border: '1px solid #74c69d'
    },
    tableTd: {
        background: '#FFF',
        padding: 10,
        border: '1px solid #74c69d'
    },
    tableButton: {
        maxHeight: '25px',
        marginRight: 3,
        fontSize: 10,
        textShadow: '0 1px rgb(0 0 0 / 21%)',
        color: '#FFF',
        background: '#74c69d',
        '&:hover': {
            backgroundColor: '#74C69D',
            transform: 'scale(1.05)'
        }
    },
    tableRow: {
        '&:hover': {
            backgroundColor: 'initial'
        }
    },
    iconWidth: {
        width: 25
    },
    subText: {
        paddingLeft: 30
    },
    emailSpan: {
        fontSize: 18,
        fontWeight: 500,
        paddingRight: '1.2rem',
    },
    anchor: {
        color: '#0066f9 !important',
        textDecoration: 'none',
        backgroundColor: 'transparent',
        cursor: 'pointer'
    }
}));

const Accordion = withStyles({
    root: {
      border: '1px solid #676a72',
      overflow: 'hidden',
      borderRadius: 10,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    //   borderBottom: '1px solid #595859',
    //   boxShadow: 'none',
    //   '&:not(:last-child)': {
    //     borderBottom: 0,
    //   },
      '&:before': {
        display: 'none',
      },
      '&:first-child': {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
      },
      '&:last-child': {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
      },
      '&:hover': {
        border: '1px solid #74c69d'
      },
      '&$expanded': {
        margin: 'auto',
        border: '2px solid #74c69d'
      },

    },
    expanded: {},
})(MuiAccordion);
  
const AccordionSummary = withStyles({
    root: {
      backgroundColor: '#FFF',
    //   borderBottom: '1px solid #595859',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
})(MuiAccordionSummary);
  
const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
      paddingLeft: '3rem'
    },
}))(MuiAccordionDetails);

export default function EmailSettingsPage(props) {
    const classes = useStyles();

    const [state, setState] = React.useState({
        showEmailConfigErrMsg: false,
        sesVerificationCheckbox: false,
        isSesVerified: false,
        showSesVerifiedErrMsg: false,
        forwardingAddressListedCheckbox: false,
        isForwardingAddressListed: false,
        showForwardingAddressMsg: false,
        forwardingFilterCheckbox: false,
        showForwardingFilterErrMsg: false,
        genericEmailForwardingFilterCheckbox: false,
        showGenericEmailForwardingFilterErrMsg: false,
        showGetGmailFowardingCodeErrMsg: false,
        verifyDnsRecordsCheckbox: false,
        showVerifyDnsRecordsCheckbox: false,
        showVerifyDnsRecordsErrMsg: false,
        stepOneDone: false,
        stepTwoDone: false,
        stepThreeOtherDone: false,
        stepThreeAGmailDone: false,
        stepThreeBGmailDone: false,
        stepFourDone: false
    });

    const [dkimTokens, setDkimTokens] = React.useState([]);

    const [expanded, setExpanded] = React.useState('panel1');

    const [emailConfigData, setEmailConfigData] = React.useState({
        email_config_id: "",
        from_address: "",
        from_full_name: "",
        forwarding_key: "",
        is_ses_identity_requested: false,
        is_ses_identity_verified: false,
        is_ses_dkim_requested: false,
        is_ses_dkim_verified: false,
        is_email_forwarding_rule_verified: false,
        inbound_parse_email: "",
        email_server_name: "",
        gmail_forwarding_confirmation_code: ""
    })

    const getEmailConfigData = (success, failure, forGmailCode) => {
        var url_string = window.location.href; 
        var url = new URL(url_string);
        var emailConfigId = url.searchParams.get("email-config-id");

        let params = {
            email_config_id: emailConfigId
        }

        if (forGmailCode) {
            params.is_gmail_confirmation = "true";
        }

        Api.getEmailConfig(params, success, failure)
    }

    React.useEffect(() => {
        props.siteLoader(true);

        getEmailConfigData(getEmailConfigSuccess, getEmailConfigFailure)

        var url_string = window.location.href; 
        var url = new URL(url_string);
        var emailConfigId = url.searchParams.get("email-config-id");

        let params = {
            email_config_id: emailConfigId
        }

        Api.getDkimTokens(params, getDkimTokensSuccess, getDkimTokensFailure)
    }, [])

    const getDkimTokensSuccess = (response) => {
        setDkimTokens(response.data);
        console.log('dkim success ', response);
    }

    const getDkimTokensFailure = (response) => {
        console.log('dkim failure ', response);
    }

    const getEmailConfigSuccess = (response) => {
        let data = response.data;

        setEmailConfigData(data);

        resetStepState(data);

        // if this is true either they dont use Gmail and they are verified OR they do use Gmail and they got the gmail 
        // forwarding confirmation code, set it up correctly and got verified

        props.siteLoader(false);

    }

    const getEmailConfigFailure = () => {
        
    }

    const resetStepState = (data) => {
        setState({
            ...state,
            isSesVerified: data.is_ses_identity_verified ? true : false,
            stepOneDone: (data.from_full_name && data.forwarding_key) ? true : false,
            stepTwoDone: data.is_ses_identity_verified ? true : false,
            stepThreeOtherDone: data.is_email_forwarding_rule_verified ? true : false,
            stepThreeAGmailDone: data.gmail_forwarding_confirmation_code ? true : false,
            stepThreeBGmailDone: data.is_email_forwarding_rule_verified ? true : false,
            stepFourDone: data.is_ses_dkim_verified ? true : false
        })
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleTextChange = (event) => {
        setEmailConfigData({
          ...emailConfigData,
          [event.target.name]: event.target.value
        })
    }
    const handleChangeCheckbox = (event) => {
        setState({ ...state, 
            [event.target.name]: event.target.checked 
          });
    }

    const handleEmailConfigSetup = () => {
        let element = document.getElementById("handleEmailConfigSetupLoader");
        element.classList.remove("d-none");

        var url_string = window.location.href; 
        var url = new URL(url_string);
        var emailConfigId = url.searchParams.get("email-config-id");

        let body = {
            email_config_id: emailConfigId,
            from_full_name: emailConfigData.from_full_name,
            forwarding_key: emailConfigData.forwarding_key
        }
        Api.updateEmailConfig(body, handleEmailConfigSetupSuccess, handleEmailConfigSetupFailure)
    }

    const handleEmailConfigSetupSuccess = (response) => {
        let data = response.data;

        let element = document.getElementById("handleEmailConfigSetupLoader");
        element.classList.add("d-none");

        setState({
            ...state,
            showEmailConfigErrMsg: (data.from_full_name && data.forwarding_key) ? false : true
        })

        if (data.from_full_name && data.forwarding_key) {
            setExpanded('panel2')
        }

        resetStepState(data);
    }

    const handleEmailConfigSetupFailure = (response) => {
        
    }

    const handleSendSingleSenderVerificationEmail = () => {
        let element = document.getElementById("handleSendSingleSenderVerificationEmailLoader");
        element.classList.remove("d-none");

        var url_string = window.location.href; 
        var url = new URL(url_string);
        var emailConfigId = url.searchParams.get("email-config-id");

        let params = {
            email_config_id: emailConfigId
        }

        Api.sendSingleSenderVerificationEmail(params, sendSingleSenderVerificationEmailSuccess, sendSingleSenderVerificationEmailFailure)
    }

    const sendSingleSenderVerificationEmailSuccess = (response) => {
        let element = document.getElementById("handleSendSingleSenderVerificationEmailLoader");
        element.classList.add("d-none");
    }

    const sendSingleSenderVerificationEmailFailure = (response) => {
        
    }

    const handleSesVerificationNextStep = () => {
        let element = document.getElementById("handleSesVerificationNextStepLoader");
        element.classList.remove("d-none");
        
        getEmailConfigData(getEmailConfigForSesVerificationSuccess, getEmailConfigForSesVerificationFailure)
    }

    const getEmailConfigForSesVerificationSuccess = (response) => {
        if (response.data.is_ses_identity_verified) {
            setState({
                ...state,
                isSesVerified: true,
                showSesVerifiedErrMsg: false,
                stepTwoDone: true
            })

            setExpanded('panel3')
        } else {
            setState({
                ...state,
                isSesVerified: false,
                showSesVerifiedErrMsg: true
            })
        }

        //update the emailConfigData
        setEmailConfigData(response.data) 

        resetStepState(response.data);

        let element = document.getElementById("handleSesVerificationNextStepLoader");
        element.classList.add("d-none");
    }

    const getEmailConfigForSesVerificationFailure = (response) => {
        
    }

    const handleGetGmailForwardingCode = () => {
        // hide message
        setState({
            ...state,
            showGetGmailFowardingCodeErrMsg: false,
        })

        let element = document.getElementById("getGmailCodeLoader");
        element.classList.remove("d-none");

        getEmailConfigData(getEmailConfigForGmailForwardingCodeSuccess, getEmailConfigForGmailForwardingCodeFailure, true)
    }

    const getEmailConfigForGmailForwardingCodeSuccess = (response) => {
        if (response.data.gmail_forwarding_confirmation_code) {
            setState({
                ...state,
                showGetGmailFowardingCodeErrMsg: false,
                stepThreeAGmailDone: true
            })

        } else {
            setState({
                ...state,
                showGetGmailFowardingCodeErrMsg: true,
                stepThreeAGmailDone: false
            })
        }
        
        setEmailConfigData(response.data);

        resetStepState(response.data);

        let element = document.getElementById("getGmailCodeLoader");
        element.classList.add("d-none");
    }

    const getEmailConfigForGmailForwardingCodeFailure = (response) => {
        
    }

    const handleGmailStepOneNextButton = () => {
        let element = document.getElementById("handleGmailStepOneNextButtonLoader");
        element.classList.remove("d-none");

        getEmailConfigData(getEmailConfigForGmailStepOneNextButtonSuccess, getEmailConfigForGmailStepOneNextButtonFailure)
    }

    const getEmailConfigForGmailStepOneNextButtonSuccess = (response) => {
        if (response.data.gmail_forwarding_confirmation_code) {
            setState({
                ...state,
                isForwardingAddressListed: true,
                showForwardingAddressMsg: false,
                showGetGmailFowardingCodeErrMsg: false,
                stepThreeAGmailDone: true
            })

            setExpanded('panel4');
        } else {
            setState({
                ...state,
                isForwardingAddressListed: false,
                showForwardingAddressMsg: true,
                showGetGmailFowardingCodeErrMsg: true,
                stepThreeAGmailDone: false
            })
        }

        //update the emailConfigData
        setEmailConfigData(response.data) 

        resetStepState(response.data);

        let element = document.getElementById("handleGmailStepOneNextButtonLoader");
        element.classList.add("d-none");
    }

    const getEmailConfigForGmailStepOneNextButtonFailure = (response) => {
        
    }

    const handleGmailStepTwoTestButton = () => {
        let element = document.getElementById("handleGmailStepTwoTestButtonLoader");
        element.classList.remove("d-none");

        var url_string = window.location.href; 
        var url = new URL(url_string);
        var emailConfigId = url.searchParams.get("email-config-id");

        let params = {
            email_config_id: emailConfigId
        }

        setState({
            ...state,
            showForwardingFilterErrMsg: false
        })

        Api.sendForwardingTestEmail(params, getSendForwardingTestEmailSuccess, getSendForwardingTestEmailFailure)
    }

    const getSendForwardingTestEmailSuccess = (response) => {
        if (response.data.is_email_forwarding_rule_verified) {
            setState({
                ...state,
                showForwardingFilterErrMsg: false,
                stepThreeBGmailDone: true
            })

            setExpanded('panel5');
        } else {
            setState({
                ...state,
                showForwardingFilterErrMsg: true,
                stepThreeBGmailDone: false
            })
        }

        let element = document.getElementById("handleGmailStepTwoTestButtonLoader");
        element.classList.add("d-none");
    }

    const getSendForwardingTestEmailFailure = (response) => {
        
    }

    const handleGenericEmailTestButton = () => {
        let element = document.getElementById("handleGenericEmailTestButtonLoader");
        element.classList.remove("d-none");

        var url_string = window.location.href; 
        var url = new URL(url_string);
        var emailConfigId = url.searchParams.get("email-config-id");

        let params = {
            email_config_id: emailConfigId
        }

        Api.sendForwardingTestEmail(params, getSendForwardingTestGenericEmailSuccess, getSendForwardingTestGenericEmailFailure)
    }

    const getSendForwardingTestGenericEmailSuccess = (response) => {
        if (response.data.is_email_forwarding_rule_verified) {
            setState({
                ...state,
                showGenericEmailForwardingFilterErrMsg: false,
                stepThreeOtherDone: true
            })

            setExpanded('panel5');
        } else {
            setState({
                ...state,
                showGenericEmailForwardingFilterErrMsg: true,
                stepThreeOtherDone: false
            })
        }

        // setEmailConfigData(response.data) 

        setExpanded('panel5');

        let element = document.getElementById("handleGenericEmailTestButtonLoader");
        element.classList.add("d-none");
    }

    const getSendForwardingTestGenericEmailFailure = (response) => {
        
    }

    const handleVerifyDkimButton = () => {
        let element = document.getElementById("handleVerifyDkimButtonLoader");
        element.classList.remove("d-none");

        getEmailConfigData(handleVerifyDkimButtonSuccess, handleVerifyDkimButtonFailure)
    }

    const handleVerifyDkimButtonSuccess = (response) => {
        if (response.data.is_ses_dkim_verified) {
            setState({
                ...state,
                showVerifyDnsRecordsErrMsg: false,
                stepFourDone: true
            })

            setExpanded('panel5');
        } else {
            setState({
                ...state,
                showVerifyDnsRecordsErrMsg: true,
                stepFourDone: false
            })
        }

        resetStepState(response.data);

        let element = document.getElementById("handleVerifyDkimButtonLoader");
        element.classList.add("d-none");
    }

    const handleVerifyDkimButtonFailure = (response) => {
        
    }
  
    return (
        <div className="container">
            <div className="row">
                <Typography variant="h4" className="mt-3 pl-3 w-100 d-flex align-items-end">Email Settings <span className={classes.emailSpan + " ml-auto"}>{emailConfigData.from_address}</span></Typography>
                <div className="col-12 pt-2">
                    <Accordion square={false} className="mb-2" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
                            <div className="d-flex flex-column">
                                <Typography><AlternateEmailIcon className={classes.iconWidth} /> Step 1</Typography>
                                <Typography variant="body2" className={classes.stepDescription}>Email Config Set-up</Typography>
                            </div>
                            
                            {state.stepOneDone ? <CheckIcon className="greenCheck ml-auto"/> : <CloseIcon className="redX ml-auto" />}
                        </AccordionSummary>
                        <AccordionDetails className="d-flex flex-column">
                            <Typography variant="body2" component="p" className="mb-3 font-weight-light">
                                Input the name that you would like to show up when emails are sent from Janium.
                            </Typography>
                            <TextField 
                                className={classes.inputFields} 
                                onChange={handleTextChange} 
                                label="From Name" 
                                type="text" 
                                variant="outlined" 
                                size="small" 
                                name="from_full_name" 
                                value={emailConfigData.from_full_name} 
                            />
                            <Typography variant="body2" component="p" className="mb-3 font-weight-light">
                                For this email sending identity, please provide a unique string of text or numbers to be used 
                                as the Janium forwarding key. For more details about the forwarding key and how it is used, click 
                                <a href={"https://app.janium.io"} target="_blank" rel="noreferrer"> here</a>.
                            </Typography>
                            <TextField 
                                className={classes.inputFields} 
                                onChange={handleTextChange} 
                                label="Email Forwarding Key" 
                                type="text" 
                                variant="outlined" 
                                size="small" 
                                name="forwarding_key" 
                                value={emailConfigData.forwarding_key} 
                            />
                            
                            <div className="d-flex align-items-center">
                                <Button size="small" variant="contained" className={classes.buttonClasses} onClick={handleEmailConfigSetup}>Save and Continue</Button>
                                <div className={classes.spinnerStyles + " spinner-border d-none ml-3"} id="handleEmailConfigSetupLoader" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            
                            { 
                                state.showEmailConfigErrMsg ?
                                    <Typography variant="caption" className="mt-1 red">
                                        These values cannot be empty.
                                    </Typography>
                                :
                                    <div></div>
                            }
                        </AccordionDetails>
                    </Accordion>

                    <Accordion square={false} className="mb-2" expanded={expanded === 'panel2'} onChange={handleChange('panel2')} disabled={state.stepOneDone ? false : true} >
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                            <div className="d-flex flex-column">
                                <Typography><EmailOutlinedIcon className={classes.iconWidth} /> Step 2</Typography>
                                <Typography variant="body2" className={classes.stepDescription}>Email Sender Verification</Typography>
                            </div>
                            {state.stepTwoDone ? <CheckIcon className="greenCheck ml-auto"/> : <CloseIcon className="redX ml-auto" />}
                        </AccordionSummary>
                        <AccordionDetails className="d-flex flex-column">
                            <Typography variant="body2" component="p" className="font-weight-light">
                                Click on the button below to be sent the single sender verification email.
                            </Typography>
                            
                            <div className="d-flex align-items-center">
                                <Button 
                                    size="small" 
                                    variant="contained" 
                                    className={classes.buttonClasses} 
                                    onClick={handleSendSingleSenderVerificationEmail}
                                >
                                    Send Verification Email
                                </Button>
                                <div className={classes.spinnerStyles + " spinner-border d-none ml-3"} id="handleSendSingleSenderVerificationEmailLoader" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            <Typography variant="body2" component="p" className="font-weight-light">
                                Please click on the link in that email to become a verified single sender within Janium.
                            </Typography>
                            <Typography variant="caption" className="font-weight-light">
                                If the email didn't show up in your inbox, click <button className={classes.anchor + " anchor"} onClick={handleSendSingleSenderVerificationEmail}>here</button> to have it resent.
                            </Typography>

                            <FormControlLabel
                                control={
                                <Checkbox
                                checked={state.sesVerificationCheckbox}
                                onChange={handleChangeCheckbox}
                                name="sesVerificationCheckbox"
                                color="primary"
                                />
                                }
                                label={<Typography variant="body2" className="font-weight-light">I have clicked on the link in the email.</Typography>}
                            />
                            
                            <div className="d-flex align-items-center">
                                <Button size="small" variant="contained" id="" disabled={state.sesVerificationCheckbox ? false : true} className={classes.buttonClasses} onClick={handleSesVerificationNextStep}>
                                    Next
                                </Button>
                                <div className={classes.spinnerStyles + " spinner-border d-none ml-3"} id="handleSesVerificationNextStepLoader" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            { 
                                state.showSesVerifiedErrMsg ?
                                    <Typography variant="caption" className="mt-1 red">
                                        Your email has not been verified, please try again.
                                    </Typography>
                                :
                                    <div></div>
                            }
                        </AccordionDetails>
                    </Accordion>

                    {
                        (emailConfigData.email_server_name === "gmail") ?
                            <div>
                                <Accordion square={false} className="mb-2" expanded={expanded === 'panel3'} onChange={handleChange('panel3')} disabled={(state.stepTwoDone && state.stepOneDone) ? false : true} >
                                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                        <div className="d-flex flex-column">
                                            <Typography><FlashOnOutlinedIcon className={classes.iconWidth} /> Step 3a</Typography>
                                            <Typography variant="body2" className={classes.stepDescription}>Email Response Integration</Typography>
                                        </div>
                                        {state.stepThreeAGmailDone ? <CheckIcon className="greenCheck ml-auto"/> : <CloseIcon className="redX ml-auto" />}
                                    </AccordionSummary>
                                    <AccordionDetails className="d-flex flex-column">
                                        <Typography variant="body2" className="font-weight-light mb-1">
                                            Go into your email settings on a browser and set up an email forwarding 
                                            rule to the email address listed below:
                                        </Typography>
                                        <Typography variant="caption" className="font-weight-light mb-2">
                                            You can see instructions on how to do this within Gmail <a href={"https://app.janium.io"} target="_blank" rel="noreferrer">here</a>.
                                        </Typography>
                                        <Typography variant="body2" className="font-weight-light">
                                            Email forwarding address: <span className={classes.fontBold}>{emailConfigData.inbound_parse_email}</span>
                                        </Typography>
                                        <div className="d-flex align-items-center">
                                            <Button size="small" variant="contained" className={classes.buttonClasses + " my-4"} onClick={handleGetGmailForwardingCode}>
                                                Get Gmail Forwarding Confirmation Code
                                            </Button>
                                            <div className={classes.spinnerStyles + " spinner-border d-none ml-3"} id="getGmailCodeLoader" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        {
                                            emailConfigData.gmail_forwarding_confirmation_code ?
                                                <div>
                                                    <Typography variant="body2" className="font-weight-light">
                                                        Gmail Forwarding Confirmation Code: <span className={classes.fontBold}>{emailConfigData.gmail_forwarding_confirmation_code}</span>
                                                    </Typography>
                                                    <Typography variant="caption" className="font-weight-light">
                                                        Please enter the code above in the "confirmation code" field next to the newly set-up forwarding address.
                                                    </Typography>
                                                </div>
                                            :
                                                <div></div>
                                        }
                                        { 
                                            state.showGetGmailFowardingCodeErrMsg ?
                                                <Typography variant="caption" className="mt-1 red">
                                                    We were unable to get your Gmail forwarding code. 
                                                    Please click "Get Gmail Fowarding Confirmation Code" to try again.
                                                </Typography>
                                            :
                                                <div></div>
                                        }
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={state.forwardingAddressListedCheckbox}
                                                onChange={handleChangeCheckbox}
                                                name="forwardingAddressListedCheckbox"
                                                color="primary"
                                            />
                                            }
                                            label={<Typography variant="body2" className="font-weight-light">I have added the email listed as a forwarding address within Gmail</Typography>}
                                        />
                                        
                                        <div className="d-flex align-items-center">
                                            <Button size="small" variant="contained" disabled={state.forwardingAddressListedCheckbox ? false : true} className={classes.buttonClasses} onClick={handleGmailStepOneNextButton}>Next</Button>
                                            <div className={classes.spinnerStyles + " spinner-border d-none ml-3"} id="handleGmailStepOneNextButtonLoader" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        { 
                                            state.showForwardingAddressMsg ?
                                                <Typography variant="caption" className="mt-1 red">
                                                    It looks like the forwarding code hasn't been set up properly, please try again.
                                                </Typography>
                                            :
                                                <div></div>
                                        }
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion square={false} className="mb-2" expanded={expanded === 'panel4'} onChange={handleChange('panel4')} disabled={(state.stepTwoDone && state.stepOneDone && state.stepThreeAGmailDone) ? false : true} >
                                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                        <div className="d-flex flex-column">
                                            <Typography><FlashOnOutlinedIcon className={classes.iconWidth} /> Step 3b</Typography>
                                            <Typography variant="body2" className={classes.stepDescription}>Email Response Integration (Continued...)</Typography>
                                        </div>
                                        {state.stepThreeBGmailDone ? <CheckIcon className="greenCheck ml-auto"/> : <CloseIcon className="redX ml-auto" />}
                                    </AccordionSummary>
                                    <AccordionDetails className="d-flex flex-column">
                                        <Typography variant="body2" className="font-weight-light">
                                            1. Set up a "Has the Words" forwarding filter with the following text key: <span className={classes.fontBold}>{emailConfigData.forwarding_key}</span>
                                        </Typography>
                                        <Typography variant="body2" className="font-weight-light">
                                            2. Check the "forward it to" box and select <span className={classes.fontBold}>{emailConfigData.inbound_parse_email}</span> as the forwarding address.
                                        </Typography>
                                        <Typography variant="body2" className="font-weight-light">
                                            3. Click Create Filter.
                                        </Typography>
                                        <Typography variant="caption" className="font-weight-light">
                                            You can see instructions on how to do this within Gmail <a href={"https://app.janium.io"} target="_blank" rel="noreferrer">here</a>.
                                        </Typography>

                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                            checked={state.forwardingFilterCheckbox}
                                            onChange={handleChangeCheckbox}
                                            name="forwardingFilterCheckbox"
                                            color="primary"
                                            />
                                            }
                                            label={<Typography variant="body2" className="font-weight-light">I have entered the code and set up the forwarding filter.</Typography>}
                                        />
                                        <div className="d-flex align-items-center">
                                            <Button size="small" variant="contained" disabled={state.forwardingFilterCheckbox ? false : true} className={classes.buttonClasses} onClick={handleGmailStepTwoTestButton}>
                                                Test and Continue
                                            </Button>
                                            <div className={classes.spinnerStyles + " spinner-border d-none ml-3"} id="handleGmailStepTwoTestButtonLoader" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        { 
                                            state.showForwardingFilterErrMsg ?
                                                <Typography variant="caption" className="mt-1 red">
                                                    It looks like the test email that Janium sent to your email didn't get forwarded back to our system, 
                                                    please check here to make sure you set up the rule correctly. Click "Test and Continue" to try again.
                                                </Typography>
                                            :
                                                <div></div>
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        :
                            <Accordion square={false} className="mb-2" expanded={expanded === 'panel3'} onChange={handleChange('panel3')} disabled={(state.stepOneDone && state.stepTwoDone) ? false : true} >
                                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                    <div className="d-flex flex-column">
                                        <Typography><FlashOnOutlinedIcon className={classes.iconWidth} /> Step 3</Typography>
                                        <Typography variant="body2" className={classes.stepDescription}>Email Response Integration</Typography>
                                    </div>
                                    {state.stepThreeOtherDone ? <CheckIcon className="greenCheck ml-auto"/> : <CloseIcon className="redX ml-auto" />}
                                </AccordionSummary>
                                <AccordionDetails className="d-flex flex-column">
                                    <Typography variant="body2" className="font-weight-light">
                                        Go into your Office 365 Outlook settings and set up a
                                        rule with the following parameters:
                                    </Typography>
                                    <Typography variant="caption" className="font-weight-light">
                                        You can see the instructions on how to do this within Outlook/Office 365 <a href={"https://app.janium.io"} target="_blank" rel="noreferrer">here</a>.
                                    </Typography>
                                    <Typography variant="body2" className="font-weight-light">
                                        1. Name the rule "Janium"
                                    </Typography>
                                    <Typography variant="body2" className="font-weight-light">
                                        2. Add a "Message body includes" keyword condition with
                                        the following text key: <span className={classes.fontBold}>{emailConfigData.forwarding_key}</span>
                                    </Typography>
                                    <Typography variant="body2" className="font-weight-light">
                                        3. Add a "Forward to" route action with the following
                                        forwarding address: <span className={classes.fontBold}>{emailConfigData.inbound_parse_email}</span>
                                    </Typography>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked={state.genericEmailForwardingFilterCheckbox}
                                            onChange={handleChangeCheckbox}
                                            name="genericEmailForwardingFilterCheckbox"
                                            color="primary"
                                        />
                                        }
                                        label={<Typography variant="body2" className="font-weight-light">I have entered the code and set up the forwarding filter.</Typography>}
                                    />
                                    
                                    <div className="d-flex align-items-center">
                                        <Button size="small" variant="contained" disabled={state.genericEmailForwardingFilterCheckbox ? false : true} className={classes.buttonClasses} onClick={handleGenericEmailTestButton}>Test and Continue</Button>
                                        <div className={classes.spinnerStyles + " spinner-border d-none ml-3"} id="handleGenericEmailTestButtonLoader" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    { 
                                        state.showGenericEmailForwardingFilterErrMsg ?
                                            <div>
                                                <Typography variant="caption" className="mt-1 red">
                                                    It looks like the test email that Janium sent to your email didn't get forwarded back to our system, 
                                                    please check here to make sure you set up the rule correctly.
                                                </Typography>
                                                <Typography variant="caption" className="mt-1 red">
                                                    Click "Test and Continue" to try again.
                                                </Typography>
                                            </div>
                                        :
                                            <div></div>
                                    }
                                </AccordionDetails>
                            </Accordion>
                    }
                    <Accordion square={false} className="mb-2" expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                            <div className="d-flex flex-column">
                                <Typography><DnsOutlinedIcon className={classes.iconWidth} /> Step 4</Typography>
                                <Typography variant="body2" className={classes.stepDescription}>DNS/Domain Verification</Typography>
                            </div>
                            {state.stepFourDone ? <CheckIcon className="greenCheck ml-auto"/> : <CloseIcon className="redX ml-auto" />}
                        </AccordionSummary>
                        <AccordionDetails className="d-flex flex-column">
                            <Typography variant="body2">Install the DNS records below to become domain verified:</Typography>
                            <table className={classes.tableRoot + " p-3 my-3"}>
                                <thead>
                                    <tr className={classes.tableRow} >
                                        <th className={classes.tableHeaders} style={{width: "10%"}}>Type</th>
                                        <th className={classes.tableHeaders} style={{width: "45%"}}>Name</th>
                                        <th className={classes.tableHeaders} style={{width: "45%"}}>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    dkimTokens.map((dkimData, i) => {
                                        return(
                                            <tr className={classes.tableRow}>
                                                <td className={classes.tableTd + " text-left"}>
                                                    <div className="d-flex align-items-center justify-content-between">{dkimData.type}</div>
                                                </td>
                                                <td className={classes.tableTd + " text-left"}>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        {dkimData.name} <Button size="small" variant="contained" className={classes.tableButton} onClick={() => {navigator.clipboard.writeText(dkimData.name)}}>Copy</Button>
                                                    </div>
                                                </td>
                                                <td className={classes.tableTd + " text-left"}>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        {dkimData.value} <Button size="small" variant="contained" className={classes.tableButton} onClick={() => {navigator.clipboard.writeText(dkimData.value)}}>Copy</Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                                
                            </table>
                            {/* <Typography className="font-weight-light">
                                You can now send emails through Janium, and Janium can also see when prospects respond to your messaging. 
                                For optimal campaign performance, we suggest going through one more step (you can revisit this step later).
                            </Typography> */}
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={state.verifyDnsRecordsCheckbox}
                                    onChange={handleChangeCheckbox}
                                    name="verifyDnsRecordsCheckbox"
                                    color="primary"
                                />
                                }
                                label={<Typography variant="body2" className="font-weight-light">I have installed the DNS records above.</Typography>}
                            />
                            
                            <div className="d-flex align-items-center">
                                <Button size="small" variant="contained" disabled={state.verifyDnsRecordsCheckbox ? false : true} className={classes.buttonClasses} onClick={handleVerifyDkimButton}>Verify</Button>
                                <div className={classes.spinnerStyles + " spinner-border d-none ml-3"} id="handleVerifyDkimButtonLoader" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            
                            { 
                                state.showVerifyDnsRecordsErrMsg ?
                                    <div>
                                        <Typography variant="caption" className="mt-1 red">
                                        We were unable to verify your domain. Most DNS record updates propagate within minutes, however, some may take up to 48 hours. 
                                        Please look here for some tips on how to properly verify your domain. Once implemented, try verifying once again.
                                        </Typography>
                                    </div>
                                :
                                    <div></div>
                            }
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </div>
    );
}