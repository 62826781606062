import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField, Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import BalloonEditor from 'ckeditor5-custom-build/build/ckeditor';
import * as Api from "../../api.js";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    divWrapperLeft: {
        minWidth: 650,
        maxWidth: 650,
        marginRight: "auto"
    },
    textFieldStyles: {
        background: "#FFF",
        borderRadius: 4,
        maxWidth: 90,
        "& .MuiOutlinedInput-root": { 
            "& fieldset": { 
                border: "2px solid #6199a5"
            },
            '&:hover fieldset': {
                borderColor: '#548692 !important',
            },
            "&.Mui-focused fieldset": {
                borderColor: "#6199a5",
                outline: "unset"
            }
        }
    },
    textFieldContactUlincIdStyles: {
        background: "#FFF",
        borderRadius: 4,
        minWidth: 145,
        width: '50%',
        "& .MuiOutlinedInput-root": { 
            "& fieldset": { 
                border: "2px solid #6199a5"
            },
            '&:hover fieldset': {
                borderColor: '#548692 !important',
            },
            "&.Mui-focused fieldset": {
                borderColor: "#6199a5",
                outline: "unset"
            }
        }
    },
    textAreaStyles: {
        minWidth: 650,
        maxWidth: 650,
        marginTop: "20px !important",
        borderRadius: 7,
        padding: "5px 8px",
        border: "2px solid #6199a5",
        "&:focus": {
            outline: "unset",
            border: "2px solid #6199a5 !important",
            boxShadow: "rgba(0, 0, 0, 0.1) 2px 2px 3px 0px inset, rgb(4, 20, 51) 0px 0px 0px 0px"
        }
    },
    accordion: {
        background: 'transparent',
        boxShadow: 'unset'
    },
    accordionDetails: {
        marginTop: '-10px'
    },
    accordionTextWrapper: {
        padding: '0 0 0 10px'
    },
    accordionText: {
        color: '#585563',
        fontWeight: 'bold'
    },
    spinnerStyles: {
        color: "#74c69d",
    }
}));

export default function LinkedInMessageDiv(props) {
    const classes = useStyles();
    const [bodyData, setBodyData] = React.useState(
        props.messageData.janium_campaign_step_body
    )
    const [delayData, setDelayData] = React.useState(
        props.messageData.janium_campaign_step_delay
    )
    const [ulincContactInfo, setUlincContactInfo] = React.useState({
        contactUlincId: "",
        contactUlincName: "",
        janiumCampaignId: ""
    })

    React.useEffect(() => {
        var url_string = window.location.href; 
        var url = new URL(url_string);
        var janiumCampaignId = url.searchParams.get("janiumCampaignId");

        const newMessageData = {
            ...props.messageData,
            "janium_campaign_step_body": bodyData,
            "janium_campaign_step_delay": parseInt(delayData),
        }

        props.inputChangeHandler(newMessageData, props.indexVal)

        if (props.testData) {
            setUlincContactInfo(props.testData)
        }
        

    }, [bodyData, delayData, props.testData])

    const handleSaveAndTestMessages = () => {
        let element = document.getElementById("saveAndTestButtonLoading" + props.indexVal);
        element.classList.remove("d-none");

        props.saveMethod(handleSendTestMessage)
    }

    const handleSendTestMessage = (campaignStepId) => {
        var url_string = window.location.href; 
        var url = new URL(url_string);
        var ulincConfigId = url.searchParams.get("ulincConfigId");

        const testMessageData = {
            // "janium_campaign_step_body": bodyData,
            "janium_campaign_id": ulincContactInfo.janiumCampaignId,
            "janium_campaign_step_id": campaignStepId,
            "ulinc_config_id": ulincConfigId,
            "contact_ulinc_id": ulincContactInfo.contactUlincId,
            // "default_ulinc_contact_name": ulincContactInfo.contactUlincName
        }

        Api.testLiMessaging(testMessageData, testLiMessagingSuccess, testLiMessagingFailure)
    }

    const testLiMessagingSuccess = (response) => {
        console.log("li message test success: ", response);

        let element = document.getElementById("saveAndTestButtonLoading" + props.indexVal);
        element.classList.add("d-none");
    }

    const testLiMessagingFailure = (response) => {
        console.log("li message test failure: ", response);

        let element = document.getElementById("saveAndTestButtonLoading" + props.indexVal);
        element.classList.add("d-none");
    }

    const handleTestDataChange = (event) => {
        props.setTestMessageIndexMethod(props.messageData.janium_campaign_message_index);
        
        setUlincContactInfo({
            ...ulincContactInfo,
            [event.target.name]: event.target.value
        })
    }


    return (
        <div className={classes.divWrapperLeft + " linkedInMessengerWrapper"}>
            <Typography variant="h6" gutterBottom>
                {"Step " + (props.messageData.janium_campaign_message_index + 2) + ": " + (props.messageData.janium_campaign_message_index ? "LinkedIn Follow-up #" + (props.messageData.janium_campaign_message_index/2 + 1) : "LinkedIn Follow-up #1")}
            </Typography>
            <div className="d-flex justify-content-between pb-0 mb-0 emailMessagingFontOverride">
                <TextField 
                    name="delay" 
                    onChange={(e) => {
                        setDelayData(e.target.value);
                    }} 
                    className={classes.textFieldStyles} 
                    id="outlined-basic" 
                    label="LI Delay" 
                    variant="outlined" 
                    size="small" 
                    value={delayData ?? ""} 
                />

                
            </div>
            
            {/* <TextareaAutosize className={classes.textAreaStyles} aria-label="LinkedIn Welcome Message" rowsMin={5} placeholder={props.placeholderText} /> */}
            <CKEditor
                activeClass="linkedInEditor"
                className={classes.textAreaStyles + " evanTest"}
                editor={ BalloonEditor }
                data={bodyData ?? ""} 
                config={{
                    placeholder: "Enter " + (props.messageData.janium_campaign_message_index ? "LinkedIn Follow-up #" + (props.messageData.janium_campaign_message_index/2 + 1) : "LinkedIn Follow-up #1")  + " message text here..."
                }}
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    setBodyData(data);
                } }

                onFocus={ ( event, editor ) => {
                    document.querySelector('.ck-body-wrapper').classList.add('d-none');
                } }
                // onClick={ (event, editor) => {
                //     const data = editor.getData();
                // }}
            />
            <Accordion 
                className={classes.accordion + " accordionStyles emailMessagingFontOverride"}
                onChange={(e, expanded) => {
                    if (expanded) {
                        props.setTestMessageIndexMethod(props.messageData.janium_campaign_message_index);
                    }
                }}
            >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon className="pt-0"/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordionTextWrapper + " mb-0"}
                >
                    <Typography variant="body2" className={classes.accordionText + " mb-0"}>Test Step</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails + " d-flex flex-column"}>
                    {/* <Typography className="mb-2 pl-3">
                        Click the name of each new connection to visit their LinkedIn profile. Then, while you are there, please follow through with a custom welcome message for those who are qualified.
                    </Typography>
                    <Typography className="mb-2 pl-3">
                        Take a minute to look at each prospect for some things to work into your custom welcome message—peruse through their profile, work history, posts, LinkedIn company page, and their website to gather intel.
                    </Typography>
                    <Typography className="d-flex pl-3">
                        For those who aren’t qualified, click the “DQ” link next to their name to remove them from the campaign and any further follow-up messaging. 
                    </Typography> */}
                    <div className="d-flex justify-content-between mb-3">
                        <TextField 
                            name="contactUlincId" 
                            onChange={handleTestDataChange}
                            className={classes.textFieldContactUlincIdStyles + " mr-4"} 
                            id="outlined-basic" 
                            label="Contact Ulinc Id" 
                            variant="outlined" 
                            size="small" 
                            value={ulincContactInfo.contactUlincId} 
                        />
                        <TextField 
                            name="contactUlincName" 
                            onChange={handleTestDataChange}
                            className={classes.textFieldContactUlincIdStyles + " mr-4"} 
                            id="outlined-basic" 
                            label="Contact Ulinc Name" 
                            variant="outlined" 
                            size="small" 
                            value={ulincContactInfo.contactUlincName} 
                        />
                    </div>
                    <div className="position-relative d-flex">
                    <Button onClick={handleSaveAndTestMessages} variant="contained" color="primary" disabled={(ulincContactInfo.contactUlincId && ulincContactInfo.contactUlincName) ? false : true}>
                            Save & Test Message Step
                        </Button>
                        <div class={classes.spinnerStyles + " spinner-border ml-2 d-none"} id={"saveAndTestButtonLoading" + props.indexVal} role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}