import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Card, CardActions, CardContent, CardHeader, Button, Typography, Switch, Modal, 
  Backdrop, Fade, TextField, FormControl, FormControlLabel, InputLabel, Select, MenuItem, Slider } from '@material-ui/core';
// import { Link } from "react-router-dom";
import {BrowserRouter as Router, useHistory, Link } from "react-router-dom";
import * as Api from "../../api.js";

const useStyles = makeStyles((theme) => ({
  tableWrapperDiv: {
    maxWidth: 1100,
    margin: "auto"
  },
  tableWrapper: {
    background: "transparent",
    boxShadow: "unset",
    position: "relative",
    display: "table",
    borderRadius: "7px",
    overflow: "hidden"
  },
  table: {
    minWidth: 600,
    borderRadius: "7px"
  },
  tableHeaders: {
    background: "#FFF"
  },
  newCampaignButton: {
    background: "#74c69d",
    color: "#FFF",
    '&:focus': {
        outline: "unset"
    },
    '&:hover': {
        background: "#63bf91",
        boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)"
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 600
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxshadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 700
  },
  inputFields: {
    minWidth: 325,
    marginBottom: 15,
    boxshadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    borderRadius: 5
  },
  timeInputFields: {
    minWidth: 100,
    marginBottom: 15,
    boxshadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    borderRadius: 5
  },
  buttonWrapper: {
    paddingLeft: 30,
    paddingRight: 30
  },
  buttonClasses: {
    background: '#74C69D',
    color: '#FFF',
    minWidth: 125,
    width: 'fit-content',
    transition: '0.1s linear',
    boxshadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    '&:hover': {
      backgroundColor: '#74C69D',
      transform: 'scale(1.05)'
    }
  },
  requiredFields: {
    fontSize: 10,
    marginLeft: 20
  },
  checkboxText: {
    lineHeight: '1.1',
    fontSize: 12,
    textAlign: 'center'
  },
  switchText: {
    fontSize: 14
  },
  sliderRoot: {
    width: 550
  },
  spinnerStyles: {
    color: "#74c69d"
  },
}));

function createData(title, type, status, contacts, connected, replied, campaignId) {
  return { title, type, status, contacts, connected, replied, campaignId };
}

function valuetext(value) {
  return `${value}°C`;
}

const marks = [
  {
    value: 0,
    label: '12AM'
  },
  {
    value: 1,
  },
  {
    value: 2,
    label: '2AM'
  },
  {
    value: 3,
  },
  {
    value: 4,
    label: '4AM'
  },
  {
    value: 5,
  },
  {
    value: 6,
    label: '6AM'
  },
  {
    value: 7,
  },
  {
    value: 8,
    label: '8AM'
  },
  {
    value: 9,
  },
  {
    value: 10,
    label: '10AM'
  },
  {
    value: 11,
  },
  {
    value: 12,
    label: '12PM'
  },
  {
    value: 13,
  },
  {
    value: 14,
    label: '2PM'
  },
  {
    value: 15,
  },
  {
    value: 16,
    label: '4PM'
  },
  {
    value: 17,
  },
  {
    value: 18,
    label: '6PM'
  },
  {
    value: 19,
  },
  {
    value: 20,
    label: '8PM'
  },
  {
    value: 21,
  },
  {
    value: 22,
    label: '10PM'
  },
  {
    value: 23,
  },
  {
    value: 23.9,
    label: '12AM'
  }
];

export default function ConnectorTable(props) {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState([9, 17]);

  //modal and create campaign logic
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [campaignData, setCampaignData] = React.useState({
    email_config_id: "",
    janium_campaign_name: "",
    janium_campaign_description: "",
    is_reply_in_email_thread: false,
    is_csv_backdated: false
  });

  const [emails, setEmails] = React.useState([]);

  React.useEffect(() => {
        
    Api.getCampaignEmails(getCampaignEmailsSuccess, getCampaignEmailsFailure)
  }, [])

  const getCampaignEmailsSuccess = (response) => {
    setEmails(response.data);
  }

  const getCampaignEmailsFailure = (response) => {
    console.log("homepage email req fail: ", response);
  }

  const handleTextChange = (event) => {
    setCampaignData({
      ...campaignData, 
      [event.target.name]: event.target.value
    })
  }
  const handleCheckboxChange = (event) => {
    setCampaignData({
      ...campaignData, 
      [event.target.name]: event.target.checked 
    });
  };
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleModalFromInputChange = (event) => {
    setValue([[event.target.value], value[1]])
  }
  const handleModalToInputChange = (event) => {
    setValue([value[0], [event.target.value]])
  }

  const handleCreateCampaignClick = () => {
    let element = document.getElementById("handleCreateCampaignClickLoader");
    element.classList.remove("d-none");

    var url_string = window.location.href; 
    var url = new URL(url_string);
    var ulincConfigId = url.searchParams.get("ulincConfigId");

    let newJaniumCampaignObj = {
      ulinc_config_id: ulincConfigId,
      email_config_id: campaignData.email_config_id,
      janium_campaign_name: campaignData.janium_campaign_name,
      janium_campaign_description: campaignData.janium_campaign_description,
      is_reply_in_email_thread: campaignData.is_reply_in_email_thread,
      is_csv_backdated: campaignData.is_csv_backdated,
      queue_start_hour: value[0],
      queue_start_minute: 0,
      queue_end_hour: value[1],
      queue_end_minute: 0
    }

    Api.createJaniumCampaign(newJaniumCampaignObj, createJaniumCampaignSuccess, createJaniumCampaignFailure)
  }

  const createJaniumCampaignSuccess = (response) => {
    let element = document.getElementById("handleCreateCampaignClickLoader");
    element.classList.add("d-none");

    let path = `/campaign/?janiumCampaignId=` + response.data.janium_campaign_id + `&ulincConfigId=` + response.data.ulinc_config_id; 
    
    history.push(path);

    console.log("create janium campaign success: ", response);
  }

  const createJaniumCampaignFailure = (response) => {
    console.log("create janium campaign failure: ", response);
  }

  const rows = [];
  
  if (props.campaignsData.length > 0) {
    props.campaignsData.forEach((campaignInfo, i) => {
      rows.push(createData(campaignInfo.janium_campaign_name, campaignInfo.janium_campaign_type, campaignInfo.janium_campaign_is_active, campaignInfo.janium_campaign_contacts, campaignInfo.janium_campaign_connected, campaignInfo.janium_campaign_replied, campaignInfo.janium_campaign_id));
    })
  }

  let url_string = window.location.href; 
  let url = new URL(url_string);
  let ulincConfigId = url.searchParams.get("ulincConfigId");

  return (
    <div className={classes.tableWrapperDiv}>
      <div className="d-flex justify-content-between pb-1">
        <div className="ml-1 mt-2 h4">Campaigns</div>
        <Button className={classes.newCampaignButton + " px-3"} size="small" onClick={handleOpen}>New Campaign</Button>
      </div>
      <TableContainer component={Paper} className={classes.tableWrapper + " tableBoxShadow m-auto"}>
        <Table className={classes.table + " campaignsTable"} aria-label="simple table">
          <TableHead className={classes.tableHeaders}>
            <TableRow>
              <TableCell align="center">Title</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Contacts</TableCell>
              <TableCell align="center">Connected</TableCell>
              <TableCell align="center">Replied</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.title}>
                <TableCell align="center" width="35%">
                  <Link title={row.title} to={{pathname: '/campaign/?janiumCampaignId='+ row.campaignId + '&ulincConfigId=' + ulincConfigId}}>{row.title}</Link>
                </TableCell>
                <TableCell align="center" width="20%">{row.type}</TableCell>
                <TableCell align="center" width="15%" className={row.status ? "green" : "red"}>{row.status ? "Active" : "Inactive"}</TableCell>
                <TableCell align="center" width="10%">{row.contacts}</TableCell>
                <TableCell align="center" width="10%">{row.connected}</TableCell>
                <TableCell align="center" width="10%">{row.replied}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Card variant="outlined">
              <CardHeader className="text-center" titleTypographyProps={{variant:'h5'}} title="New Campaign" />
              <CardContent className="d-flex flex-column align-items-center">
                <TextField 
                  className={classes.inputFields} 
                  onChange={handleTextChange} 
                  label="Campaign Name" 
                  type="text" 
                  variant="outlined" 
                  size="small" 
                  name="janium_campaign_name" 
                  value={campaignData.janium_campaign_name} 
                />
                <TextField 
                  className={classes.inputFields} 
                  onChange={handleTextChange} 
                  label="Campaign Description" 
                  type="text" 
                  variant="outlined" 
                  size="small" 
                  name="janium_campaign_description" 
                  value={campaignData.janium_campaign_description} 
                />
                <FormControl className={classes.inputFields}>
                    <InputLabel id="janiumEmailId">Email Id</InputLabel>
                    <Select
                        labelId="janiumEmailId"
                        name="email_config_id" 
                        value={campaignData.email_config_id}
                        onChange={handleTextChange}
                    >
                        {
                            emails.map((emailData, i) => {
                                return (
                                    <MenuItem key={i} value={emailData.email_config_id}>{emailData.from_full_name + " <" + emailData.from_address + ">"}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
                <div className={classes.sliderRoot + " d-flex flex-column my-2"}>
                  <Typography id="range-slider" gutterBottom className="text-center">
                    Daily Campaign Message Sending Window ({props.timezone})
                  </Typography>
                  <Slider
                    value={value}
                    onChange={handleSliderChange}
                    valueLabelDisplay="off"
                    aria-labelledby="range-slider"
                    getAriaValueText={valuetext}
                    marks={marks}
                    max={23.9}
                  />
                  <div className="d-flex justify-content-around">
                    <FormControl className={classes.timeInputFields}>
                        <InputLabel id="messageFromTime">From</InputLabel>
                        <Select
                            labelId="messageFromTime"
                            // name="email_config_id" 
                            value={value[0]}
                            onChange={handleModalFromInputChange}
                        >
                          <MenuItem value={0}>12 AM</MenuItem>
                          <MenuItem value={1}>1 AM</MenuItem>
                          <MenuItem value={2}>2 AM</MenuItem>
                          <MenuItem value={3}>3 AM</MenuItem>
                          <MenuItem value={4}>4 AM</MenuItem>
                          <MenuItem value={5}>5 AM</MenuItem>
                          <MenuItem value={6}>6 AM</MenuItem>
                          <MenuItem value={7}>7 AM</MenuItem>
                          <MenuItem value={8}>8 AM</MenuItem>
                          <MenuItem value={9}>9 AM</MenuItem>
                          <MenuItem value={10}>10 AM</MenuItem>
                          <MenuItem value={11}>11 AM</MenuItem>
                          <MenuItem value={12}>12 PM</MenuItem>
                          <MenuItem value={13}>1 PM</MenuItem>
                          <MenuItem value={14}>2 PM</MenuItem>
                          <MenuItem value={15}>3 PM</MenuItem>
                          <MenuItem value={16}>4 PM</MenuItem>
                          <MenuItem value={17}>5 PM</MenuItem>
                          <MenuItem value={18}>6 PM</MenuItem>
                          <MenuItem value={19}>7 PM</MenuItem>
                          <MenuItem value={20}>8 PM</MenuItem>
                          <MenuItem value={21}>9 PM</MenuItem>
                          <MenuItem value={22}>10 PM</MenuItem>
                          <MenuItem value={23}>11 PM</MenuItem>
                          <MenuItem value={23.9}>12 AM</MenuItem>   
                        </Select>
                    </FormControl>

                    <FormControl className={classes.timeInputFields}>
                        <InputLabel id="messageToTime">To</InputLabel>
                        <Select
                            labelId="messageToTime"
                            // name="email_config_id" 
                            value={value[1]}
                            onChange={handleModalToInputChange}
                        >
                          <MenuItem value={0}>12 AM</MenuItem>
                          <MenuItem value={1}>1 AM</MenuItem>
                          <MenuItem value={2}>2 AM</MenuItem>
                          <MenuItem value={3}>3 AM</MenuItem>
                          <MenuItem value={4}>4 AM</MenuItem>
                          <MenuItem value={5}>5 AM</MenuItem>
                          <MenuItem value={6}>6 AM</MenuItem>
                          <MenuItem value={7}>7 AM</MenuItem>
                          <MenuItem value={8}>8 AM</MenuItem>
                          <MenuItem value={9}>9 AM</MenuItem>
                          <MenuItem value={10}>10 AM</MenuItem>
                          <MenuItem value={11}>11 AM</MenuItem>
                          <MenuItem value={12}>12 PM</MenuItem>
                          <MenuItem value={13}>1 PM</MenuItem>
                          <MenuItem value={14}>2 PM</MenuItem>
                          <MenuItem value={15}>3 PM</MenuItem>
                          <MenuItem value={16}>4 PM</MenuItem>
                          <MenuItem value={17}>5 PM</MenuItem>
                          <MenuItem value={18}>6 PM</MenuItem>
                          <MenuItem value={19}>7 PM</MenuItem>
                          <MenuItem value={20}>8 PM</MenuItem>
                          <MenuItem value={21}>9 PM</MenuItem>
                          <MenuItem value={22}>10 PM</MenuItem>
                          <MenuItem value={23}>11 PM</MenuItem>
                          <MenuItem value={23.9}>12 AM</MenuItem>   
                        </Select>
                    </FormControl>
                  </div>
                </div>

                {/* <TextField 
                  className={classes.inputFields} 
                  onChange={handleTextChange} 
                  label="Email Id" 
                  type="text" 
                  variant="outlined" 
                  size="small" 
                  name="email_config_id" 
                  value={campaignData.email_config_id} 
                /> */}
                
                <FormControlLabel
                  control={
                  <Switch
                      checked={campaignData.is_reply_in_email_thread}
                      onChange={handleCheckboxChange}
                      name="is_reply_in_email_thread"
                      color="primary"
                  />
                  }
                  label={<Typography>Reply Email In Thread</Typography>}
                  className="d-flex flex-row-reverse justify-content-end pl-2"
                /> 
                <FormControlLabel
                  control={
                  <Switch
                      checked={campaignData.is_csv_backdated}
                      onChange={handleCheckboxChange}
                      name="is_csv_backdated"
                      color="primary"
                  />
                  }
                  label={<Typography>Backdate Ulinc Contact Creation</Typography>}
                  className="d-flex flex-row-reverse justify-content-end pl-2"
                /> 
              </CardContent>
              <CardActions className={classes.buttonWrapper}>
                <div className="d-flex align-items-center w-100 justify-content-center">
                <Button size="small" variant="contained" onClick={handleCreateCampaignClick} className={classes.buttonClasses}>Create Campaign</Button>
                    <div className={classes.spinnerStyles + " spinner-border d-none ml-3"} id="handleCreateCampaignClickLoader" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                
              </CardActions>

              {/* <span className={classes.requiredFields}>*Required Fields</span> */}
            </Card>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
