import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Card, CardActions, CardContent, CardHeader, Button, Typography, Modal, Backdrop, Fade, TextField, FormControlLabel, Radio, RadioGroup, FormControl } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import {BrowserRouter as useHistory, Link } from "react-router-dom";
import * as Api from "../../api.js";

const useStyles = makeStyles((theme) => ({
  tableWrapperDiv: {
    maxWidth: 1100,
    margin: "auto"
  },
  tableWrapper: {
    background: "transparent",
    boxShadow: "unset",
    position: "relative",
    display: "table",
    borderRadius: "7px",
    overflow: "hidden"
  },
  table: {
    minWidth: 600,
    borderRadius: "7px"
  },
  tableHeaders: {
    background: "#FFF"
  },
  newCampaignButton: {
    background: "#74c69d",
    color: "#FFF",
    '&:focus': {
        outline: "unset"
    },
    '&:hover': {
        background: "#63bf91",
        boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)"
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxshadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 675
  },
  inputFields: {
    minWidth: 275,
    marginBottom: 15,
    boxshadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    borderRadius: 5
  },
  buttonWrapper: {
    paddingLeft: 30,
    paddingRight: 30
  },
  buttonClasses: {
    background: '#74C69D',
    color: '#FFF',
    minWidth: 125,
    width: '100%',
    transition: '0.1s linear',
    boxshadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    '&:hover': {
      backgroundColor: '#74C69D',
      transform: 'scale(1.05)'
    }
  },
  requiredFields: {
    fontSize: 10,
    marginLeft: 20
  },
  checkboxText: {
    lineHeight: '1.1',
    fontSize: 12,
    textAlign: 'center'
  },
  switchText: {
    fontSize: 14
  },
  actionButton: {
    color: "#0A369D",
    fontSize: 14
  },
  settingsButtonStyles: {
    color:'#0A369D',
    minWidth: 0,
    marginTop: 4,
    marginBottom: 4,
    marginLeft: 10,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '0.00938em'
  }
}));

function createData(emailAccounts, singleSenderVer, forwardingRuleVer, domainVer, emailConfigId) {
  return { emailAccounts, singleSenderVer, forwardingRuleVer, domainVer, emailConfigId};
}

export default function EmailIntegrationTable(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  //modal and create campaign logic
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    setAddEmailData({
      ...addEmailData,
      from_address: "",
      from_address_confirm: "",
      email_server_name: "gmail"
    })
  };

  // const [state, setState] = React.useState({
  //   campaignName: "",
  //   messageTriggeredCampaign: false,
  //   campaignIsActive: false
  // });

  const [addEmailData, setAddEmailData] = React.useState({
    from_address: "",
    from_address_confirm: "",
    from_full_name: "",
    email_server_name: "gmail",
    forwarding_key: "",
    moduleStep: "one",
    confirmEmailMessage: ""
  })

  const [emailConfig, setEmailConfigData] = React.useState({
    emailConfigData: []
  })

  React.useEffect(() => {
    Api.getEmailSettingsConfigs(getEmailConfigDataSuccess, getEmailConfigDataFailure);
  }, [])

  const getEmailConfigDataSuccess = (response) => {
    // console.log('settings page email config settings call success ', response.data);

    setEmailConfigData({...emailConfig, emailConfigData: response.data});
  }

  const getEmailConfigDataFailure = (response) => {
    console.log('settings page email config settings call failed ', response);
  }

  const handleAddEmailTextChange = (event) => {
    setAddEmailData({
      ...addEmailData,
      [event.target.name]: event.target.value,
      confirmEmailMessage: ""
    })
  }

  const handleAddEmailSender = () => {
    if (addEmailData.from_address !== addEmailData.from_address_confirm) {
      setAddEmailData({
        ...addEmailData,
        confirmEmailMessage: "emails do not match"
      })

      return;
    }

    let params = {
      from_address: addEmailData.from_address,
      email_server_name: addEmailData.email_server_name
    }

    Api.addEmailConfig(params, handleAddEmailSenderSuccess, handleAddEmailSenderFailure)
  }

  const handleAddEmailSenderSuccess = (response) => {
    // history.push("/email-settings/?email-config-id=" + response.data.email_config_id);
    props.emailSettingsRedirect(response.data.email_config_id);

    console.log("success: ", response);
  }

  const handleAddEmailSenderFailure = (response) => {
    console.log("success: ", response);
  }

  const rows = [];
  
  if (emailConfig.emailConfigData.length > 0) {
    emailConfig.emailConfigData.forEach((configData, i) => {
      rows.push(createData(configData.from_address, configData.is_ses_identity_verified, configData.is_email_forwarding_rule_verified, configData.is_ses_dkim_verified, configData.email_config_id));
    })
  }

  return (
    <div className={classes.tableWrapperDiv}>
      <div className="d-flex justify-content-between pb-1">
        <div className="ml-1 mt-2 h4">Email Integration</div>
        {/* <Button className={classes.newCampaignButton + " px-3"} size="small" onClick={handleOpen}>New Campaign</Button> */}
      </div>
      <TableContainer component={Paper} className={classes.tableWrapper + " tableBoxShadow m-auto"}>
        <Table className={classes.table + " campaignsTable"} aria-label="simple table">
          <TableHead className={classes.tableHeaders}>
            <TableRow>
              <TableCell align="center">Email Account(s):</TableCell>
              <TableCell align="center">Single Sender Verification</TableCell>
              <TableCell align="center">Forwarding Rule Verification</TableCell>
              <TableCell align="center">DKIM Verification</TableCell>
              <TableCell align="center">Email Settings</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={i}>
                <TableCell align="center" width="25%"><Link className={classes.settingsButtonStyles} title="Email Account" to={{pathname: '/email-settings/?email-config-id=' + row.emailConfigId}}>{row.emailAccounts}</Link></TableCell>
                <TableCell align="center" width="20%">{row.singleSenderVer ? <CheckIcon className="greenCheck"/> : <CloseIcon className="redX" />}</TableCell>
                <TableCell align="center" width="25%">{row.forwardingRuleVer ? <CheckIcon className="greenCheck"/> : <CloseIcon className="redX" />}</TableCell>
                <TableCell align="center" width="15%">{row.domainVer ? <CheckIcon className="greenCheck"/> : <CloseIcon className="redX" />}</TableCell>
                <TableCell align="center" width="15%"><Link className={classes.settingsButtonStyles} title="Email Settings" to={{pathname: '/email-settings/?email-config-id=' + row.emailConfigId}}>Settings</Link></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="w-100 text-center py-2">
            <Button className={classes.actionButton} size="small" onClick={handleOpen}>Add Email Account</Button>
        </div>
      </TableContainer>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Card variant="outlined" className="p-1">
              <div>
                <CardHeader className="text-center pb-0 pt-1 mb-0" titleTypographyProps={{variant:'h5'}} title="Step 1: Email Sending Set-up" />
                <CardContent className="d-flex flex-column align-items-center">
                  <Typography variant="body2" component="p" className="text-center mb-3 ">
                    Enter the email address you would like to use for sending emails through your Janium campaigns
                  </Typography>
                  <TextField 
                    className={classes.inputFields} 
                    onChange={handleAddEmailTextChange} 
                    label="From Email Address" 
                    type="text" 
                    variant="outlined" 
                    size="small" 
                    name="from_address" 
                    value={addEmailData.from_address} 
                  />
                  <TextField 
                    className={classes.inputFields} 
                    onChange={handleAddEmailTextChange} 
                    label="Confirm From Email Address" 
                    type="text" 
                    variant="outlined" 
                    size="small" 
                    name="from_address_confirm" 
                    value={addEmailData.from_fullfrom_address_confirm_name} 
                    error={addEmailData.confirmEmailMessage}
                    helperText={addEmailData.confirmEmailMessage}
                  />
                  <Typography variant="body2" className="text-center mt-1 mb-2">
                    Which of the email providers below is the email provider for the email listed? (select one)
                  </Typography>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="email server name" name="email_server_name" value={addEmailData.email_server_name} onChange={handleAddEmailTextChange}>
                      <FormControlLabel className="mb-0" value="gmail" control={<Radio size="small" />} label="Gmail" />
                      <FormControlLabel className="mb-0" value="office_365" control={<Radio size="small" />} label="Office 365" />
                      <FormControlLabel className="mb-0" value="exchange" control={<Radio size="small" />} label="Exchange" />
                      <FormControlLabel className="mb-0" value="other" control={<Radio size="small" />} label="Other" />
                    </RadioGroup>
                  </FormControl>
                </CardContent>
                <CardActions className={classes.buttonWrapper + " pt-0 mt-0"}>
                  <Button variant="contained" className={classes.buttonClasses} onClick={handleAddEmailSender}>Next</Button>
                </CardActions>
              </div>
              {/* <span className={classes.requiredFields}>*Required Fields</span> */}
            </Card>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
