import React from "react";
import {BrowserRouter as Router, Route, Switch, Redirect, useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import { NavBar } from "./components";
import { Login, Signup, Accounts, AccountHomePage, SettingsPage, CampaignPage, EmailSettingsPage, SubscriptionsPage } from "./views";
import * as Api from "./api.js";
import Cookies from 'js-cookie';
import "@fontsource/montserrat";

import "./app.css";

const useStyles = makeStyles({
  root: {
    fontFamily: "MontSerrat !important"
  },
  spinnerStyles: {
    color: "#74c69d"
  }
});

const App = () => {
  const location = useLocation();
  const classes = useStyles();

  const [accountInfo, setAccountInfo] = React.useState({
    accountId: null,
    isSignedIn: false,
  });

  const [billingInfo, setBillingInfo] = React.useState({
    "is_admin_paid": false,
    "has_payment_method": false,
    "current_subscription_price": {
      "current_subscription_price_id": "",
      "current_subscription_price_name": "",
      "current_subscription_price": 0
    },
    "seats": 0,
    "payment_method": {
        "payment_method_id": "",
        "payment_method_last_4": "",
        "payment_method_holder_name": "",
        "payment_method_issuer": ""
    },
    "subscription_prices": [
      {
        "subscription_price_name": "",
        "subscription_price_id": "",
        "subscription_price": ""
      },
      {
        "subscription_price_name": "",
        "subscription_price_id": "",
        "subscription_price": ""
      }
    ]
  })

  React.useEffect(() => {
    if (Cookies.get('access_token_cookie')) {
      Api.getAccountBillingInfo(getAccountBillingInfoSuccess, getAccountBillingInfoFailure)
    }
  }, [accountInfo])

  const getAccountBillingInfoSuccess = (response) => {
    setBillingInfo(response.data);
  }

  const getAccountBillingInfoFailure = (response) => {
    console.log("setting page email req fail: ", response);
  }

  let removeSiteLoaderOnFailure;

  const setSiteLoader = (setLoader) => {
    let appEntry = document.getElementById("appEntry");
    let siteLoader = document.getElementById("siteLoader");

    if (setLoader) {
      removeSiteLoaderOnFailure = setTimeout(function() {
        setSiteLoader(false);
        alert("There was an issue with either your network or ours. Please log out and try again.");
      }, 20000);

      appEntry.classList.add("blur");
      siteLoader.classList.remove("d-none");
    } else {
      appEntry.classList.remove("blur");
      siteLoader.classList.add("d-none");
      clearTimeout(removeSiteLoaderOnFailure);
    }

  }

  return (
    <Router>
      <div id="app" className={classes.root + " d-flex flex-column pb-3 position-relative"} id="appEntry">
          <div className={classes.spinnerStyles + " spinner-border ml-3 d-none siteLoader"} id="siteLoader" role="status">
              <span className="sr-only">Loading...</span>
          </div>
        
        {
          (accountInfo.isSignedIn || Cookies.get('access_token_cookie')) ? <NavBar setAccountInfo={setAccountInfo} adminPaid={billingInfo.is_admin_paid} /> : ((location.pathname === "/sign-up") ? <Redirect to={location.pathname + location.search}  /> : <Redirect to="/" />)
        }
          <Switch>
            <Route path="/" exact render={() => {
              if (accountInfo.isSignedIn === true || Cookies.get('access_token_cookie')) {
                return <Redirect to="/accounts" />
              } else {
                return <Login accountInfo={accountInfo} setAccountInfo={setAccountInfo}/>
              }
            }}/>

            <Route path="/sign-up"  render={() => {
                return <Signup />
            }} />

            <Route path="/accounts"  render={() => {
              if (Cookies.get('access_token_cookie')) {
                return <Accounts accountInfo={accountInfo} billingInfo={billingInfo} siteLoader={setSiteLoader} />
              } else {
                return <Login accountInfo={accountInfo} setAccountInfo={setAccountInfo}/>
              }
            }} />
            <Route path="/accountHomePage" render={(prop) => {
              if (Cookies.get('access_token_cookie')) {
                return <AccountHomePage siteLoader={setSiteLoader} />
              } else {
                return <Login accountInfo={accountInfo} setAccountInfo={setAccountInfo}/>
              }
            }} />
            <Route path="/settings" render={(prop) => {
              if (Cookies.get('access_token_cookie')) {
                return <SettingsPage siteLoader={setSiteLoader} />
              } else {
                return <Login accountInfo={accountInfo} setAccountInfo={setAccountInfo}/>
              }
            }} />
            <Route path="/campaign" render={(prop) => {
              if (Cookies.get('access_token_cookie')) {
                return <CampaignPage siteLoader={setSiteLoader} subscriptionPlan={billingInfo.current_subscription_price.current_subscription_price_name} />
              } else {
                return <Login accountInfo={accountInfo} setAccountInfo={setAccountInfo}/>
              }
            }} />

            <Route path="/email-settings"  render={() => {
              if (Cookies.get('access_token_cookie')) {
                return <EmailSettingsPage siteLoader={setSiteLoader} />
              } else {
                return <Login accountInfo={accountInfo} setAccountInfo={setAccountInfo}/>
              }
            }} />

            <Route path="/subscription"  render={() => {
                return <SubscriptionsPage siteLoader={setSiteLoader} />
            }} />
          </Switch>
      </div> 
    </Router>
  );
};

export default App;
