import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, FormControlLabel, Typography, Switch, Button, FormControl, 
    InputLabel, Select, MenuItem, Slider }  from '@material-ui/core';
import * as Api from "../../api.js";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    messagesContainer: {
        background: "linear-gradient(#74C69D, #FFF)",
        marginTop: 20,
        borderRadius: 7,
        padding: "10px 8px",
        display: "flex",
        flexDirection: "column",
        maxWidth: 1250,
        margin: "0 auto"
    },
    buttonClass: {
        width: "fit-content", 
        background: "#74c69d",
        color: "#FFF",
        fontWeight: "400",
        textTransform: "capitalize",
        "&:hover": {
            background: "#63bf91"
        }
    },
    secondaryButton: {
        background: "#74C69D",
        color: "#FFF",
        "&:hover": {
            background: "#63bf91"
        }
    },
    inputFields: {
        minWidth: 275,
        marginBottom: 15,
        boxshadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
        borderRadius: 5,
        display: 'block'
    }, 
    input: {
        minWidth: 275
    },
    messageWindowHeader: {
        marginLeft: '-3px'
    }
}));


export default function CampaignSettingsAndStats(props) {
    const classes = useStyles();

    const [campaignData, setCampaignData] = React.useState({
        email_config_from_address: "",
        email_config_from_full_name: "",
        email_config_id: "",
        is_csv_backdated: "",
        janium_campaign_description: "",
        janium_campaign_id: "",
        is_active: "",
        janium_campaign_name: "",
        janium_campaign_num_connected: "",
        janium_campaign_num_contacts: "",
        janium_campaign_num_replied: "",
        janium_campaign_num_steps: "",
        janium_campaign_type: "",
        is_reply_in_email_thread: "",
        queue_start_hour: 0,
        queue_start_minute: 0,
        queue_end_hour: 0,
        queue_end_minute: 0,
    });

    const [emails, setEmails] = React.useState([]);
    const [value, setValue] = React.useState([0, 24]);

    React.useEffect(() => {
        props.siteLoader(true);

        var url_string = window.location.href; 
        var url = new URL(url_string);
        var janiumCampaignId = url.searchParams.get("janiumCampaignId");

        let params = {
            "janium_campaign_id": janiumCampaignId
        }
        Api.getCampaignSettings(params, getCampaignSettingsSuccess, getCampaignSettingsFailure)
    }, [])

    React.useEffect(() => {
        Api.getCampaignEmails(getCampaignEmailsSuccess, getCampaignEmailsFailure)
    }, [])

    const getCampaignEmailsSuccess = (response) => {
        setEmails(response.data);
    }

    const getCampaignEmailsFailure = (response) => {
        console.log("setting page email req fail: ", response);
    }

    React.useEffect(() => {
        console.log(campaignData);
    }, [campaignData])

    const getCampaignSettingsSuccess = (response) => {
        setCampaignData(response.data);

        // set values of slider here
        setValue([response.data.queue_start_hour, response.data.queue_end_hour])

        props.siteLoader(false);
    }

    const getCampaignSettingsFailure = (response) => {
        console.log("setting page fail: ", response);
    }

    const handleTextChange = (event) => {
        setCampaignData({
            ...campaignData,
            [event.target.name]: event.target.value
        })
    }

    const handleCheckboxChange = (event) => {
        setCampaignData({ 
            ...campaignData, 
            [event.target.name]: event.target.checked 
        });
    };

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleModalFromInputChange = (event) => {
        setValue([[event.target.value], value[1]])
    }
    const handleModalToInputChange = (event) => {
        setValue([value[0], [event.target.value]])
    }

    function valuetext(value) {
        return `${value}°C`;
    }

    const marks = [
        {
          value: 0,
          label: '12AM'
        },
        {
          value: 1,
        },
        {
          value: 2,
          label: '2AM'
        },
        {
          value: 3,
        },
        {
          value: 4,
          label: '4AM'
        },
        {
          value: 5,
        },
        {
          value: 6,
          label: '6AM'
        },
        {
          value: 7,
        },
        {
          value: 8,
          label: '8AM'
        },
        {
          value: 9,
        },
        {
          value: 10,
          label: '10AM'
        },
        {
          value: 11,
        },
        {
          value: 12,
          label: '12PM'
        },
        {
          value: 13,
        },
        {
          value: 14,
          label: '2PM'
        },
        {
          value: 15,
        },
        {
          value: 16,
          label: '4PM'
        },
        {
          value: 17,
        },
        {
          value: 18,
          label: '6PM'
        },
        {
          value: 19,
        },
        {
          value: 20,
          label: '8PM'
        },
        {
          value: 21,
        },
        {
          value: 22,
          label: '10PM'
        },
        {
          value: 23,
        },
        {
          value: 23.9,
          label: '12AM'
        }
      ];

    const handleSaveChanges = () => {
        let settingData = {
            "janium_campaign_id": campaignData.janium_campaign_id,
            "email_config_id": campaignData.email_config_id,
            "janium_campaign_name": campaignData.janium_campaign_name,
            "janium_campaign_description": campaignData.janium_campaign_description,
            "is_active": campaignData.is_active,
            "is_reply_in_email_thread": campaignData.is_reply_in_email_thread,
            "is_csv_backdated": campaignData.is_csv_backdated,
            "queue_start_hour": value[0],
            "queue_start_minute": 0,
            "queue_end_hour": value[1],
            "queue_end_minute": 0
        }

        Api.updateCampaignSettingChanges(settingData, updateCampaignSettingChangesSuccess, updateCampaignSettingChangesFailure)
    }

    const updateCampaignSettingChangesSuccess = (response) => {
        setCampaignData(response.data);

        // set values of slider here
        setValue([response.data.queue_start_hour, response.data.queue_end_hour])
        
        console.log("setting update success: ", response.data)
    }

    const updateCampaignSettingChangesFailure = (response) => {
        console.log("setting update failed: ", response)
    }

    return (
        <div>
            <div className="w-50">
                <TextField 
                    className={classes.inputFields} 
                    onChange={handleTextChange} 
                    label="Janium Campaign Name" 
                    type="text" 
                    variant="standard"
                    name="janium_campaign_name" 
                    value={campaignData.janium_campaign_name} 
                    InputProps={{
                        className: classes.input,
                    }}
                />
                <TextField 
                    className={classes.inputFields} 
                    onChange={handleTextChange} 
                    label="Janium Campaign Description" 
                    type="text" 
                    variant="standard"
                    name="janium_campaign_description" 
                    value={campaignData.janium_campaign_description} 
                    InputProps={{
                        className: classes.input,
                    }}
                />
                <FormControl className={classes.inputFields}>
                    <InputLabel id="janiumCampaignEmailConfig">Janium Campaign Email Config</InputLabel>
                    <Select
                        labelId="janiumCampaignEmailConfig"
                        name="email_config_id"
                        value={campaignData.email_config_id}
                        onChange={handleTextChange}
                    >
                        {
                            emails.map((emailData, i) => {
                                return (
                                    <MenuItem key={i} value={emailData.email_config_id}>{emailData.from_full_name + " <" + emailData.from_address + ">"}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
                <FormControlLabel
                    control={
                    <Switch
                        checked={campaignData.is_active}
                        onChange={handleCheckboxChange}
                        name="is_active"
                        color="primary"
                    />
                    }
                    label="Campaign Active Status"
                    className="d-flex flex-row-reverse justify-content-end pl-2"
                />
                <FormControlLabel
                    control={
                    <Switch
                        checked={campaignData.is_reply_in_email_thread}
                        onChange={handleCheckboxChange}
                        name="is_reply_in_email_thread"
                        color="primary"
                    />
                    }
                    label={<Typography>Reply Email In Thread</Typography>}
                    className="d-flex flex-row-reverse justify-content-end pl-2"
                /> 
                <FormControlLabel
                    control={
                    <Switch
                        checked={campaignData.is_csv_backdated}
                        onChange={handleCheckboxChange}
                        name="is_csv_backdated"
                        color="primary"
                    />
                    }
                    label={<Typography>Backdate Ulinc Contact Creation</Typography>}
                    className="d-flex flex-row-reverse justify-content-end pl-2"
                /> 

                <div className={classes.sliderRoot + " d-flex flex-column my-3"}>
                  <Typography id="range-slider" gutterBottom className={classes.messageWindowHeader}>
                    Daily Campaign Message Sending Window ({campaignData.time_zone_name})
                  </Typography>
                  <Slider
                    className="ml-3"
                    value={value}
                    onChange={handleSliderChange}
                    valueLabelDisplay="off"
                    aria-labelledby="range-slider"
                    getAriaValueText={valuetext}
                    marks={marks}
                    max={23.9}
                  />
                  <div className="d-flex justify-content-around">
                    <FormControl className={classes.timeInputFields}>
                        <InputLabel id="messageFromTime">From</InputLabel>
                        <Select
                            labelId="messageFromTime"
                            // name="email_config_id" 
                            value={value[0]}
                            onChange={handleModalFromInputChange}
                        >
                          <MenuItem value={0}>12 AM</MenuItem>
                          <MenuItem value={1}>1 AM</MenuItem>
                          <MenuItem value={2}>2 AM</MenuItem>
                          <MenuItem value={3}>3 AM</MenuItem>
                          <MenuItem value={4}>4 AM</MenuItem>
                          <MenuItem value={5}>5 AM</MenuItem>
                          <MenuItem value={6}>6 AM</MenuItem>
                          <MenuItem value={7}>7 AM</MenuItem>
                          <MenuItem value={8}>8 AM</MenuItem>
                          <MenuItem value={9}>9 AM</MenuItem>
                          <MenuItem value={10}>10 AM</MenuItem>
                          <MenuItem value={11}>11 AM</MenuItem>
                          <MenuItem value={12}>12 PM</MenuItem>
                          <MenuItem value={13}>1 PM</MenuItem>
                          <MenuItem value={14}>2 PM</MenuItem>
                          <MenuItem value={15}>3 PM</MenuItem>
                          <MenuItem value={16}>4 PM</MenuItem>
                          <MenuItem value={17}>5 PM</MenuItem>
                          <MenuItem value={18}>6 PM</MenuItem>
                          <MenuItem value={19}>7 PM</MenuItem>
                          <MenuItem value={20}>8 PM</MenuItem>
                          <MenuItem value={21}>9 PM</MenuItem>
                          <MenuItem value={22}>10 PM</MenuItem>
                          <MenuItem value={23}>11 PM</MenuItem>
                          <MenuItem value={23.9}>12 AM</MenuItem>   
                        </Select>
                    </FormControl>

                    <FormControl className={classes.timeInputFields}>
                        <InputLabel id="messageToTime">To</InputLabel>
                        <Select
                            labelId="messageToTime"
                            // name="email_config_id" 
                            value={value[1]}
                            onChange={handleModalToInputChange}
                        >
                          <MenuItem value={0}>12 AM</MenuItem>
                          <MenuItem value={1}>1 AM</MenuItem>
                          <MenuItem value={2}>2 AM</MenuItem>
                          <MenuItem value={3}>3 AM</MenuItem>
                          <MenuItem value={4}>4 AM</MenuItem>
                          <MenuItem value={5}>5 AM</MenuItem>
                          <MenuItem value={6}>6 AM</MenuItem>
                          <MenuItem value={7}>7 AM</MenuItem>
                          <MenuItem value={8}>8 AM</MenuItem>
                          <MenuItem value={9}>9 AM</MenuItem>
                          <MenuItem value={10}>10 AM</MenuItem>
                          <MenuItem value={11}>11 AM</MenuItem>
                          <MenuItem value={12}>12 PM</MenuItem>
                          <MenuItem value={13}>1 PM</MenuItem>
                          <MenuItem value={14}>2 PM</MenuItem>
                          <MenuItem value={15}>3 PM</MenuItem>
                          <MenuItem value={16}>4 PM</MenuItem>
                          <MenuItem value={17}>5 PM</MenuItem>
                          <MenuItem value={18}>6 PM</MenuItem>
                          <MenuItem value={19}>7 PM</MenuItem>
                          <MenuItem value={20}>8 PM</MenuItem>
                          <MenuItem value={21}>9 PM</MenuItem>
                          <MenuItem value={22}>10 PM</MenuItem>
                          <MenuItem value={23}>11 PM</MenuItem>
                          <MenuItem value={23.9}>12 AM</MenuItem>   
                        </Select>
                    </FormControl>
                  </div>
                </div>

                    
                <Button onClick={handleSaveChanges} variant="contained" color="primary" className={classes.buttonClass + " mt-3"}>
                    Save
                </Button>
            </div>
        </div>
    );

}